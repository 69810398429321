import React from 'react';
import { withUserContext } from '../../../../contexts/UserContext';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import { MDBCard, MDBCardBody, MDBCardText, MDBTooltip } from 'mdb-react-ui-kit';
import BasicButton from '../../../SharedComponents/BasicButton/BasicButton';
import Popover from '@material-ui/core/Popover';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import MasterLicensorModal from './MasterLicensorModal';
import SelectField from '../../../SharedComponents/SelectField/SelectField';
import SearchSelectField from '../../../SharedComponents/SearchSelectField';
import BasicTextField from '../../../SharedComponents/BasicTextField/BasicTextField';
import BasicCheckbox from '../../../SharedComponents/BasicCheckbox/BasicCheckbox';
import BasicLabel from '../../../SharedComponents/BasicLabel/BasicLabel';
import TableComponent from '../../../SharedComponents/Table';
import DateField from '../../../SharedComponents/DateField/DateField';
import './SyncLicensor.scss';
import CueSheetPublisher from './CueSheetPublisher';
import CheckRequestPopUp from './CheckRequestPopUp';
import MainViewModalComponent from "../../../../Common/MainViewModalLayout/Modal/Modal";
import MessageModal from '../../../SharedComponents/MessageModal';
import { initialCheckRequestConfig } from './Config';
import NotesModal from './NotesModal';
import clearTrackService from '../../../../services/service';
import * as Constants from '../../../../constants/constants';
import NotificationComponent from '../../../../Common/NotificationComponent/NotificationComponent';
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from 'axios';
import { clearanceRecordPermissionsConfig } from '../Config';
import { getFormattedDate, validateNumDotOnly, validateNumDotWithNegative, validateNumDotWithNegativeDecimals, validateNumDotWith3Decimals } from "../../../../Common/Helper"
let is_check_request_updated = 0, is_licensor_optional_rights_updated = 0, is_rights_updated = 0, viewLicensorName, viewIntialRightsInfoPermission,
    viewIntialRightsCheckRequest, viewLicenseStatuses, viewLicenseTrackingModal, editLicensorName, addRemoveChangeLicensor, editIntialRightsInfoPermission, viewOptionaRightsAndCheckRequest, configInlineEdits, updated_status_id, updated_status_name;
export default withUserContext(class SyncLicensor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFetchingMasterLicensorDetails: false,
            setPopover: null,
            setCheckRequestPopUp: null,
            setModal: null,
            setEditCuePopover: null,
            optionsConfig: this.props?.selectOptionList,
            renderList: this.props?.syncLicensorList, //check request list
            config: this.props?.syncLicensorConfig,
            syncIconList: this.props?.syncIconList,
            setEPSModal: null,
            showLettersModal: false,
            showMainTitleLettersModal: false,
            showMainLicenseTackingModal: false,
            showLicenseTackingModal: false,
            showContactModal: false,
            showEditLicensorModal: false,
            showPayeeModal: false,
            syncLicensorFlagConfig: this.props?.syncLicensorFlagConfig,
            setFlagPopover: null,
            isEdit: null,
            clearneceLicenserInfo: this.props?.clearneceLicenserInfo,
            licensorData: [],
            status: this.props?.status,
            allrightsofsystem: [],
            optionalRightsConfig: this.props?.optionalRightsConfig,
            CueSheetPublisherConfig: this.props?.CueSheetPublisherConfig,
            mode: this.props?.mode,
            index: this.props?.index,
            lic: this.props?.lic,
            isFieldChanged: false,
            optionalRightsList: this.props?.optionalRightsList,
            showSuccess: false,
            showFail: false,
            saveFlag: false,
            postingLicensor: false,
            postingMainTitleLicensor: false,
            isLicensorOptionalRightsUpdated: 0,
            licenseTrackingData: {},
            setEpsCountModal: false,
            epsCountFlag: null,
            searchCancelToken: null,
            isLicensorFetching:false,
            isLicenseeRepFetching: false,
            episodeOptions: [],
            eps_cnt_list: [],
            allPayeeRemitTo: [],
            epsCount: null,
            postLicensorValidate: false,
            showChkReqAutoAdjust: false,
            isDoneAutoAdjustPopup: false,
            openPayeeModal: null,
            inValidChkReq: false,
            inValidOptionalReq: false,
            inValidOptionalChkReq: false,
            MandatoryCheckRequestError:false,
            optionalChkReqUpdated:false,
            selectedCheckRequest:null,
            defaultPayee:null,
            defaultAllPayee:[],
            isSubmit: false,
            selectedChkReqIndex:null,
            clearanceRecordPermissionsConfig: clearanceRecordPermissionsConfig,
            licensorTrackingPermission: null,
            lettersPermission: null,
            lQCPermissions: null,
            statusCROptionsPermissions: null,
            statusChangeAlert: null,
            changeStatusOfAllLicensors: false,
            isExpanded: true
        }
    }


    static getDerivedStateFromProps(nextProps, state) {
        if (nextProps?.lic?.editing !== state?.lic?.editing) {
            let data=nextProps?.lic;
                if(data?.use_confirmation_date != state?.lic?.use_confirmation_date){
                    const today = new Date();
                    let currentDate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
                    data.use_confirmation_date = currentDate;
                }
                if(data?.confirmation_date != state?.lic?.confirmation_date){
                    const today = new Date();
                    let currentDate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
                    data.confirmation_date = currentDate;
                }
                if(data?.quote_request_date != state?.lic?.quote_request_date){
                    const today = new Date();
                    let currentDate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
                    data.quote_request_date = currentDate;
                    
                }
                 if (data?.license_request_date != state?.lic?.license_request_date){
                    const today = new Date();
                    let currentDate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
                    data.license_request_date = currentDate;
                }
            return {
                lic: data,
                renderList: nextProps?.syncLicensorList,
                optionalRightsList: nextProps?.optionalRightsList,
                isDoneAutoAdjustPopup: false,
                ...(nextProps?.lic?.default_payee_contact_name && nextProps?.lic?.default_payee_remit_to_id) && { defaultPayee: { payee_remit_to: { text: nextProps?.lic?.default_payee_contact_name, value: nextProps?.lic?.default_payee_remit_to_id } } }
            }
        }
        if (JSON.stringify(nextProps?.selectOptionList) !== JSON.stringify(state?.selectOptionList)) {
            return {
                optionsConfig: nextProps?.selectOptionList,
            }
        }
        // if (nextProps.config !== state.config) {
        //     if (nextProps?.isMainTitle) {
        //         let headingObj = {
        //             headingLabel: "icon",
        //             inputType: "",
        //             border: true,
        //             width: "9%"
        //         }
        //         let licenseConfig = state.config
        //         licenseConfig.headings.splice(1,0,headingObj)
        //         // this.setState({ config: licenseConfig })
        //         console.log("config",licenseConfig)
        //     }
        // }
    }
    handleClick = (event) => {
        this.setState({
            setPopover: event.currentTarget
        })
    };

    handleClickModal = (event) => {
        this.setState({
            setModal: event.currentTarget
        })
    };

    handleClickEPSModal = (dataItem, event) => {
        this.setState({
            setEPSModal: event.currentTarget
        })
    };

    handleCloseEPSModal = () => {
        this.setState({
            setEPSModal: null
        })
    };

    handleClose = () => {
        this.setState({
            setPopover: null
        })
    };

    handleCloseModal = () => {
        this.setState({
            setModal: null
        })
    };

    handleFieldChanged(value) {
        this.setState({ isFieldChanged: value })
    }

    handleSyncPopupClick = (id, dataItem, index, e) => {
        // let selectedCheckRequest = {};
        // if (!this.props?.isMainTitle) {
        //     selectedCheckRequest = this.state.lic?.clearance_check_request[index]
        // } else {
        //     selectedCheckRequest = this.state.lic?.main_title_check_request[index]
        // }
        this.setState({
            setCheckRequestPopUp: e.currentTarget,
            selectedCheckRequest: dataItem,
            selectedChkReqIndex:index
        })
    };

    handleShowFlagPopover = (event) => {
        this.setState({
            setFlagPopover: event.currentTarget
        })
    };

    handleCloseFlagPopup = () => {
        this.setState({
            setFlagPopover: null
        })
    };

    handleChange = () => {
        // this.setState({ expanded: !isExpanded });
        this.setState((prevState) => ({isExpanded: !prevState.isExpanded}));
    };

    handleshowEditLettersModal = () => {
        this.setState({ showLettersModal: true })
    }
    handleHideLettersModal = () => {
        this.setState({ showLettersModal: false })
    }
    handleShowMainTitleLettersModal = () => {
        this.setState({ showMainTitleLettersModal: true })
    }
    handleHideMainTitleLettersModal = () => {
        this.setState({ showMainTitleLettersModal: false })
    }
    handleShowMainLicenseTackingModal = () => {
        this.setState({ showMainLicenseTackingModal: true })
    }
    handleHideMainLicenseTackingModal = () => {
        this.setState({ showMainLicenseTackingModal: false })
    }
    handleshowLicenseTackingModal = () => {
        this.setState({ showLicenseTackingModal: true })
    }
    handleHideLicenseTackingModal = () => {
        this.setState({ showLicenseTackingModal: false })
    }
    handleshowEditLicensorModal = (mode) => {
        this.setState({ showEditLicensorModal: true, isEdit: mode, setPopover: null });
    }
    handleHideEditLicensorModal = () => {
        this.setState({ showEditLicensorModal: false });
    }
    handleshowContactModal = () => {
        this.setState({ showContactModal: true, setPopover: null });
    }
    handleHideContactModal = () => {
        this.setState({ showContactModal: false });
    }
    handleShowPayeeModal = (mode) => {
        this.setState({ showPayeeModal: true, isEdit: mode })
    }
    handleHidePayeeMOdal = () => {
        this.setState({ showPayeeModal: false })
    }


    componentDidUpdate(prevProps, prevState) {
        // Typical usage (don't forget to compare props):
        if (this.props.clearneceLicenserInfo !== prevProps.clearneceLicenserInfo) {
            this.setState({ clearneceLicenserInfo: this.props.clearneceLicenserInfo });
        }
        if (this.props.mode !== prevProps.mode) {
            this.setState({ mode: this.props.mode });
        }
        if (JSON.stringify(this.props?.status) !== JSON.stringify(prevProps?.status)) {
            this.setState({ status: this.props?.status })
        }
        if (this.props?.lic?.editing != prevProps?.lic?.editing) {
            if (this.props?.lic?.editing === true) {
                let optionalRightsConfig = JSON.parse(JSON.stringify(this.state?.optionalRightsConfig))
                optionalRightsConfig.actions.push("times");

                let CueSheetPublisherConfig = JSON.parse(JSON.stringify(this.state?.CueSheetPublisherConfig))
                CueSheetPublisherConfig.actions.push("times");

                let syncLicensorConfig = JSON.parse(JSON.stringify(this.state?.config))
                syncLicensorConfig.actions.push("times");
                this.setState({ optionalRightsConfig, CueSheetPublisherConfig, config: syncLicensorConfig })
            }
            else if (this.props?.lic?.editing === false) {
                let optionalRightsConfig = JSON.parse(JSON.stringify(this.state?.optionalRightsConfig))
                optionalRightsConfig.actions = optionalRightsConfig?.actions?.filter(e => e != "times");

                let CueSheetPublisherConfig = JSON.parse(JSON.stringify(this.state.CueSheetPublisherConfig))
                CueSheetPublisherConfig.actions = CueSheetPublisherConfig?.actions?.filter(e => e != "times");

                let syncLicensorConfig = JSON.parse(JSON.stringify(this.state?.config))
                syncLicensorConfig.actions = syncLicensorConfig?.actions?.filter(e => e != "times");
                this.setState({ optionalRightsConfig, CueSheetPublisherConfig, config: syncLicensorConfig })
            }
            if (this.props?.isMainTitle) {
                // this.getPayeeData();
                this.getDefaultPayee();
            }
          
        }
        if (this.state.lic?.licensor_id !== prevState?.lic?.licensor_id) {
             this.getDefaultPayee();
        }
    }

    componentDidMount() {
        this.alterOptionalConfig();
        this.getLicensorData();
        this.getLicensorRepData("USER");
        this.getRightsData();
        this.getTermData();
        this.getTerritoryData();       
        // this.getPayeeData();
        this.getDefaultPayee();
        // this.setDefaultCheckRequestLicAndShare();
        this.getLicensorTrakingRecord();
        this.getShowBasedEpisodes();
        if (this.state.lic && this.state.lic?.editing) {
            let data = { ...this.state.lic }
            data.selected_licensee_rep = { value: this.state.lic?.licensee_rep_id, text: this.state.lic?.licensee_rep_name }
            this.setState({ lic: data })
        }
        // this.getPermissions(this.props?.permissionArrClearanceRecords)
        // if(this.state?.lic?.){
        //     let obj = {}
        //     this.setState({})
        // }
        this.setState({ 
            licensorTrackingPermission : this.props?.isMainTitle ? this.props.mainTitlePermissions?.find((per) => this.state.clearanceRecordPermissionsConfig.find((item) => item.buttonName == "Licensor Tracking" && item.permissions.includes(per.permission_name))) : this.props.clearancePermissions?.find((per) => this.state.clearanceRecordPermissionsConfig.find((item) => item.buttonName == "Licensor Tracking" && item.permissions.includes(per.permission_name))),
            lettersPermission: this.props?.isMainTitle ? this.props.mainTitlePermissions?.find((per) => this.state.clearanceRecordPermissionsConfig.find((item) => item.buttonName == "Letters" && item.permissions.includes(per.permission_name))) : this.props.clearancePermissions?.find((per) => this.state.clearanceRecordPermissionsConfig.find((item) => item.buttonName == "Letters" && item.permissions.includes(per.permission_name))),
            lQCPermissions: this.props?.isMainTitle ?  this.props.mainTitlePermissions?.find((per) => this.state.clearanceRecordPermissionsConfig.find((item) => item.buttonName == "Letters: Quote Request, Confirmation" && item.permissions.includes(per.permission_name))) : this.props.clearancePermissions?.find((per) => this.state.clearanceRecordPermissionsConfig.find((item) => item.buttonName == "Letters: Quote Request, Confirmation" && item.permissions.includes(per.permission_name))),
            statusCROptionsPermissions: this.props.clearancePermissions?.find((per) => this.state.clearanceRecordPermissionsConfig.find((item) => item.buttonName == "statusCROptions" && item.permissions.includes(per.permission_name)))
        })
        // for new Licensors
        if (this.props?.lic?.editing === true) {
            let optionalRightsConfig = JSON.parse(JSON.stringify(this.state?.optionalRightsConfig))
            optionalRightsConfig.actions.push("times");

            let CueSheetPublisherConfig = JSON.parse(JSON.stringify(this.state?.CueSheetPublisherConfig))
            CueSheetPublisherConfig.actions.push("times");

            let syncLicensorConfig = JSON.parse(JSON.stringify(this.state?.config))
            syncLicensorConfig.actions.push("times");
            this.setState({ optionalRightsConfig, CueSheetPublisherConfig, config: syncLicensorConfig })
        }
    }
    alterOptionalConfig() {
        let optionalConfig = { ...this.state.optionalRightsConfig }
        optionalConfig.primaryKey = [];
        if (this.props?.isMainTitle) {
            optionalConfig?.primaryKey?.push("main_title_licensor_optional_rights_id");
        } else {
            optionalConfig?.primaryKey?.push("clearance_licensor_optional_rights_id");
        }

        let optionalChkReqConfig = { ...this.state.CueSheetPublisherConfig }
        optionalChkReqConfig.primaryKey = [];
        if (this.props?.isMainTitle) {
            optionalChkReqConfig?.primaryKey?.push("main_title_licensor_optional_check_requests_id");
        } else {
            optionalChkReqConfig?.primaryKey?.push("clearance_licensor_optional_check_requests_id");
        }
        this.setState({ optionalRightsConfig: optionalConfig, CueSheetPublisherConfig: optionalChkReqConfig })
    }
    // getPermissions(permissions) {
    //     if (permissions.length > 0) {
    //         viewLicensorName = permissions.filter((obj, index) => obj.permission_name === "Can view Licensor Name");
    //         viewLicensorName = viewLicensorName.length > 0 ? true : false;
    //         viewIntialRightsInfoPermission = permissions.filter((obj, index) => obj.permission_name === "Can view Initial Rights information");
    //         viewIntialRightsInfoPermission = viewIntialRightsInfoPermission.length > 0 ? true : false;
    //         editIntialRightsInfoPermission = permissions.filter((obj, index) => obj.permission_name === "Can edit Initial Rights information");
    //         editIntialRightsInfoPermission = editIntialRightsInfoPermission.length > 0 ? true : false;
    //         viewIntialRightsCheckRequest = permissions.filter((obj, index) => obj.permission_name === "Can view Initial Rights Check Requests");
    //         viewIntialRightsCheckRequest = viewIntialRightsCheckRequest.length > 0 ? true : false;
    //         viewOptionaRightsAndCheckRequest = permissions.filter((obj, index) => obj.permission_name === "Can view Option Rights and Check Requests");
    //         viewOptionaRightsAndCheckRequest = viewOptionaRightsAndCheckRequest.length > 0 ? true : false;
    //         viewLicenseStatuses = permissions.filter((obj, index) => obj.permission_name === "Can view License statuses");
    //         viewLicenseStatuses = viewLicenseStatuses.length > 0 ? true : false;
    //         viewLicenseTrackingModal = permissions.filter((obj, index) => obj.permission_name === "Can open License Tracking modal");
    //         viewLicenseTrackingModal = viewLicenseTrackingModal.length > 0 ? true : false;
    //         editLicensorName = permissions.filter((obj, index) => obj.permission_name === "Can edit a Licensor's information");
    //         editLicensorName = editLicensorName.length > 0 ? true : false;
    //         addRemoveChangeLicensor = permissions.filter((obj, index) => obj.permission_name === "Can add/remove/change a Licensor");
    //         addRemoveChangeLicensor = addRemoveChangeLicensor.length > 0 ? true : false;
    //     }
    // }

    getShowBasedEpisodes = () => {
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/showSeasonEpisodes?show_season_id=${this.props.showSeasonId}`, this.props.userContext?.active_tenant?.tenant_id, this.props?.division_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.show_season_episode_id, text: item.episode_title }));
                this.setState({ episodeOptions: formattedList });
            },
                (err) => {
                    console.log("Error in fetching Details:", err)
                })
    }

    getLicensorTrakingRecord = () => {
        this.setState({ isFetchingLicenseTrakingDetails: true })
        let extensionUrl = null;
        if (!this.props.isMainTitle) {
            extensionUrl = `/licenseTracking?clearance_licensor_id=${this.state.lic.clearance_licensor_id}`
        } else {
            extensionUrl = `/licenseTracking?main_title_licensor_id=${this.state.lic?.main_title_licensor_id}`
        }
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            extensionUrl,
            this.props.userContext?.active_tenant?.tenant_id, this.props?.division_id)
            .then(response => {
                let licenseTrackingRecords = response.data[0]
                this.setState({
                    licenseTrackingData: licenseTrackingRecords
                })
            },
                (err) => {
                    console.log("Error in fetching LicenseTrackingDetails:", err);
                    this.setState({ isFetchingLicenseTrakingDetails: false });
                })

    }

    handleAddNewItem = (newDataItem) => {
        if (newDataItem && Object.keys(newDataItem)?.length !== 0) {

            let checkRequestKey = !this.props?.isMainTitle ? "clearance_check_request" : "main_title_check_request";
            // let currentList = this.state.lic?.[checkRequestKey] && this.state.lic?.[checkRequestKey]?.length > 0 ? JSON.parse(JSON.stringify(this.state.lic?.[checkRequestKey])) : [];                
            let currentList = this.state?.renderList?.length > 0 ? JSON.parse(JSON.stringify(this.state?.renderList)) : [];
            let checkRequestDetails = { ...initialCheckRequestConfig };
            if (this.props?.isMainTitle) {
                checkRequestDetails['main_title_check_request_id'] = null;
                delete checkRequestDetails.clearance_check_request_id;
            }
            checkRequestDetails.id = Date.now();
            checkRequestDetails.payee_remit_to = newDataItem?.payee_remit_to || null;
            checkRequestDetails['payee_remit_to_name'] = this.state.config?.headings?.[1]?.selectOptions?.find(item => item.value === newDataItem?.payee_remit_to?.value)?.text;
            checkRequestDetails.lic_per = newDataItem?.lic_per || null;
            checkRequestDetails.share = newDataItem?.share || null;
            checkRequestDetails.init_fee = newDataItem?.init_fee || null;
            checkRequestDetails.requested_date = newDataItem?.requested_date || null;
            checkRequestDetails.inv = `${this.props?.show_name} ${this.props?.selectedSong?.song_title}`;
            checkRequestDetails.notes = newDataItem?.notes || null;
            checkRequestDetails.in_batch = newDataItem?.in_batch || null;
            checkRequestDetails['editing'] = true;
            currentList?.push(checkRequestDetails);
            is_check_request_updated = 1;
            this.onStateLicChange(checkRequestKey, currentList)
            this.setState({ renderList: currentList, MandatoryCheckRequestError: false })
        }

    }

    handleDeleteCheckRequest = (id, dataItem) => {
        id = !id ? dataItem?.id : id;
        let currentList = this.state.renderList?.length > 0 ? [...this.state.renderList] : [];
        let checkRequestKey = !this.props?.isMainTitle ? "clearance_check_request" : "main_title_check_request";
        currentList?.map(item => {
            if (item[checkRequestKey + '_id'] === id || (item.id === id)) {
                item['is_delete'] = 1;
                return item;
            }
        })
        //set in active check request
        let temp = [];
        this.state.inActiveCheckRequests?.map((item) => {
            temp.push(item);
        });
        let checkRequestList = currentList?.filter(item => item.is_delete === 1);
        temp.push(checkRequestList[0]);
        this.setState({ inActiveCheckRequests: temp })

        //filter active check request items to render in table
        let filteredList = currentList?.filter(item => item?.is_delete != 1);
        is_check_request_updated = 1
        this.onStateLicChange(checkRequestKey, filteredList)
        // this.setState({renderList:filteredList})
        if (!this.props?.lic?.editing) {
            this.postLicensorData();
        }
    }
    calculateLicShareAmount(lic, share, initFee) {
        let mainShare = this.state.lic?.share;
        let totalFee = this.state?.lic?.rights?.[0]?.fee;
        let mainLic = 100;
        // calculate LIC
        if (!lic && share) {
            return parseFloat(mainLic) * parseFloat(share) / parseFloat(mainShare);
        }
        // calcualte SHARE
        else if (lic && !share) {
            return (parseFloat(mainShare) * parseFloat(lic) / parseFloat(mainLic))?.toFixed(3);
        }
        // calculate share based on initFee 
        else if (!initFee && lic) {
            return (parseFloat(totalFee) * parseFloat(lic) / parseFloat(mainLic))?.toFixed(3);
        }
        // calculate INIT fee
        else if (!initFee && share) {
            return (parseFloat(totalFee) * parseFloat(share) / parseFloat(mainShare))?.toFixed(3);
        }
        // calculate share based on initFee 
        else if (initFee && !share) {
            return parseFloat(mainShare) * parseFloat(initFee) / parseFloat(totalFee)
        }
        else {
            return null;
        }
    }
    // 
    updateOptCheckRequest = (updatedList, index, nodeName) => {
        if ((nodeName === "share" || nodeName === "lic_per" || nodeName === "init_fee") && !this.state.lic?.share) {
            this.setState({
                showValidationAlert: true,
                sharePercentageError: "Please add Share for the licensor"
            })
            return false;
        }
        let share, lic, amount;
        if (nodeName === "payee_remit_to") {
            updatedList[index].payee_remit_to_id = updatedList[index].payee_remit_to?.value
        }
        else if (nodeName === "lic_per") {
            share = this.calculateLicShareAmount(updatedList[index]?.lic_per, null, null);
            amount = this.calculateLicShareAmount(updatedList[index]?.lic_per, share, null);
            lic = updatedList[index]?.lic_per;
            // updatedList[index].share = parseInt(updatedList[index]?.share) / parseInt(updatedList[index]?.lic_per);
        } else if (nodeName === "share") {
            share = updatedList[index]?.share //parseFloat(updatedList[index]?.share)?.toFixed(3);
            lic = this.calculateLicShareAmount(null, share, null);
            amount = this.calculateLicShareAmount(lic, share, null);

        } else {

        }
        if (nodeName === "share" || nodeName === "lic_per") {
            if (parseFloat(share) > parseFloat(this.state.lic?.share)) {
                // updatedList[index].share = null;
                this.setState({
                    showValidationAlert: true,
                    sharePercentageError: "Share % for this check cannot be more than this Licensor's share."
                })
                return;
            }
            updatedList[index].lic_per = !isNaN(lic) ? lic : null;
            updatedList[index].share = !isNaN(share) ? share : null;;
            updatedList[index].init_fee = nodeName !== "init_fee" ? !isNaN(amount) ? amount : null : updatedList[index].init_fee;

        }

        is_check_request_updated = 1
        this.setState({ renderList: updatedList })
    }
    handleDefaultValuePopulation = (value, nodeName) => {
        if ((nodeName === "share" || nodeName === "lic_per" || nodeName === "init_fee") && !this.state?.lic?.share) {
            this.setState({
                showValidationAlert: true,
                sharePercentageError: "Please add Share for the licensor"
            })
            return false;
        }
        if (nodeName === 'payee_remit_to' && value) {
            let shareValues = [];
            let licValue = {
                node: "lic_per",
                value: 100
            }
            let shareVal = {
                node: "share",
                value: parseFloat(this.state.lic?.share)
            }
            let initFee = {
                node: "init_fee",
                value: parseFloat(this.state?.lic?.rights?.[0]?.fee)
            }
            shareValues.push(licValue, shareVal, initFee);
            return shareValues;

        }
        if (nodeName === 'lic_per') {
            let shareValues = [];
            let licValue = {
                node: "lic_per",
                value: value
            }
            let shareVal = {
                node: "share",
                value: this.calculateLicShareAmount(value, null, null)
            }
            let initFee = {
                node: "init_fee",
                value: this.calculateLicShareAmount(value, shareVal?.value, null)
            }
            shareValues.push(licValue, shareVal, initFee);
            return shareValues;
        }
        if (nodeName === 'share') {
            let shareValues = [];
            let licValue = {
                node: "lic_per",
                value: this.calculateLicShareAmount(null, value, null)
            }
            let shareVal = {
                node: "share",
                value: value
            }
            let initFee = {
                node: "init_fee",
                value: this.calculateLicShareAmount(licValue?.value, value, null)
            }
            shareValues.push(licValue, shareVal, initFee);
            return shareValues;
        }
        return [];
    }
    handleAutoAdjustChkReq() {
        let totalChkFee = this.state?.renderList?.reduce((acc, o) => {
            return o?.init_fee && parseFloat(o?.init_fee) ? (acc + parseFloat(o?.init_fee)) : (acc + 0)
        }, 0);
        // alert(totalChkFee);
        let adjustedTotalChkFee = totalChkFee - parseFloat(this.state?.renderList?.[0]?.init_fee);
        // alert(adjustedTotalChkFee);
        let renderList = [...this.state?.renderList];
        let autoAdjustAmount = parseFloat(this.state?.lic?.rights?.[0]?.fee) - adjustedTotalChkFee;
        renderList[0].init_fee = autoAdjustAmount;
        renderList[0].share = autoAdjustAmount != 0 ? this.calculateLicShareAmount(null, null, autoAdjustAmount) : 0;
        renderList[0].lic_per = autoAdjustAmount != 0 ? this.calculateLicShareAmount(null, renderList[0]?.share, null) : 0;
        // alert(renderList[0].init_fee);
        // alert(renderList[0].share);
        // alert(renderList[0].lic);
        this.setState({ renderList: renderList, showChkReqAutoAdjust: false }, () => {
            this.postLicensorData();
        })


    }
    onChangeCallback = (field, value, index) => {
        let rights = this.state.lic?.rights;
        let main_title_licensor_optional_rights;
        if (this.props?.isMainTitle) {
            main_title_licensor_optional_rights = this.state.lic?.main_title_licensor_optional_rights?.length > 0 ? [...this.state.lic?.main_title_licensor_optional_rights] : [];
        }
        if (field === 'licensor_id') {
            this.onStateLicChange(field, value?.value);
            this.onStateLicChange('licensor_name', value?.text);
            this.onStateLicChange('clearance_licensor_name', value);
        } else if (field === 'selected_licensee_rep') {
            this.onStateLicChange("selected_licensee_rep", value);
            this.onStateLicChange('licensee_rep_id', value);
            let repName = this.state?.licensorRepOptions?.filter(i => i.value === value);
            this.onStateLicChange('licensee_rep_name', repName?.[0]?.label);
        } else if (field === 'eps_cnt_list') {
            let val = value.length
            this.setState({ eps_cnt_list: value, epsCount: val })
        } else if (field === 'main_title_licensor_optional_check_requests') {
            let findIndex = -1;
            for (let i = 0; i < main_title_licensor_optional_rights.length; i++) {
                const innerArray = [...main_title_licensor_optional_rights[i].main_title_licensor_optional_check_requests];
                for (let j = 0; j < innerArray.length; j++) {
                    if (innerArray[j].main_title_licensor_optional_check_requests_id === value[index].main_title_licensor_optional_check_requests_id) {
                        findIndex = i;
                    }
                }
            }
            if (findIndex > -1) {
                // let findIndex = main_title_licensor_optional_rights.find(item => item.main_title_licensor_optional_check_requests_id === value[index].main_title_licensor_optional_check_requests_id)
                main_title_licensor_optional_rights[findIndex].main_title_licensor_optional_check_requests = [...value];
                main_title_licensor_optional_rights[findIndex].is_licensor_optional_check_requests_updated = 1;
                this.onStateLicChange('main_title_licensor_optional_rights', main_title_licensor_optional_rights);
            }
        }
        else if (index) {
            rights[index][field] = value;
            this.onStateLicChange('rights', rights);
        } else if (field === 'fee') {
            rights[index][field] = value;
            this.onStateLicChange('rights', rights, index, field);

        } else if (field === 'right_id') {
            rights[index][field] = value;
            this.onStateLicChange('rights', rights);
        } else if (field === 'term_id') {
            rights[index][field] = value;
            this.onStateLicChange('rights', rights);
        } else if (field === "territory_id") {
            rights[index][field] = value;
            this.onStateLicChange('rights', rights);
            this.updateOptionalRightsTerritory(value);
        } else if (field === 'share') {
            this.onStateLicChange(field, value);
            // this.calculateCheckRequestShare(value);
        } else if (field === "inv_notes" || field === "cr_notes" || field === "op_inv_notes" || field === "op_notes") {
            this.handleInlineNotesOnChange(field, value);
        } else {
            this.onStateLicChange(field, value);
        }
        is_rights_updated = 1
    }
    //for main share validation
    validateSharePercentage = (sharePercentage) => {
        if (parseFloat(sharePercentage) > 100) {
            this.setState({
                showSharePercentExceededPopUp: true,
                sharePercentExceededErrorText: 'Total share percentage for licensor is greater than 100%. Press OK to accept or CANCEL to correct.'
            })
        }
        else {
            this.setChkReqOnMainShareFeeChanged();
        }
    }
    // handle main share auto adjust
    handleShareAutoAdjust = () => {
        this.onStateLicChange("share", 100);
        this.setState({ showSharePercentExceededPopUp: false })
    }

    updateOptionalRightsTerritory = (territoryValue) => {
        let optionalRightKey = !this.props?.isMainTitle ? "clearance_licensor_optional_rights" : "main_title_licensor_optional_rights";
        let optionalRightsList = [...this.state.lic[optionalRightKey]];
        optionalRightsList?.map(item => {
            item.territory_id = territoryValue
            return item;
        })
        this.onStateLicChange(optionalRightKey, optionalRightsList);
    }
    handleEpisodeListOnChange = (field, value, rowIndex) => {

    }
    handleInlineNotesOnChange = (field, value) => {
        let checkRequestList = this.state.renderList?.length > 0 ? [...this.state.renderList] : [];
        let checkRequestKey = !this.props?.isMainTitle ? "clearance_check_request" : "main_title_check_request";
        let optionalRightKey = !this.props?.isMainTitle ? "clearance_licensor_optional_rights" :
            "main_title_licensor_optional_rights";
        let optionalRightCheckRequestKey = !this.props?.isMainTitle ? "clearance_licensor_optional_check_requests" :
            "main_title_licensor_optional_check_requests";
        let optionalRightsList = [...this.state.lic[optionalRightKey]];
        if (field === "inv_notes") {
            checkRequestList[this.state.checkRequestInv?.invItemIndex].inv = value;
            is_check_request_updated = 1;
            this.onStateLicChange(checkRequestKey, checkRequestList);
        } else if (field === "cr_notes") {
            checkRequestList[this.state.checkRequestNotes?.notesItemIndex].notes = value;
            is_check_request_updated = 1;
            this.onStateLicChange(checkRequestKey, checkRequestList);
        } else if (field === "op_inv_notes") {
            optionalRightsList?.map(item => {
                if (item[optionalRightKey + '_id'] === this.state.optionalPayeeInv[optionalRightKey + '_id']) {
                    this.setState({ isLicensorOptionalRightsUpdated: 1 })
                    item.is_licensor_optional_check_requests_updated = 1;
                    item[optionalRightCheckRequestKey][this.state.optionalPayeeInv?.invItemIndex].inv = value;
                }
                return item;
            })
            this.onStateLicChange(optionalRightKey, optionalRightsList);
        } else if (field === "op_notes") {
            optionalRightsList?.map(item => {
                if (item[optionalRightKey + '_id'] === this.state.optionalPayeeNotes[optionalRightKey + '_id']) {
                    this.setState({ isLicensorOptionalRightsUpdated: 1 })
                    item.is_licensor_optional_check_requests_updated = 1;
                    item[optionalRightCheckRequestKey][this.state.optionalPayeeNotes?.notesItemIndex].notes = value;
                }
                return item;
            })
            this.onStateLicChange(optionalRightKey, optionalRightsList);
        }
        this.setState({renderList:checkRequestList})
    }



    setDefaultCheckRequestLicAndShare = () => {
        let checkRequestDetails = this.state.renderList?.length > 0 ? [...this.state.renderList] : [];
        checkRequestDetails?.map(item => {
            item.lic_per = item?.lic_per ? item?.lic_per : 100;
            item.share = item?.share ? item?.share : this.state.lic?.share;
            item.init_fee = !item?.init_fee ? (item.share / 100) * this.state?.lic?.rights?.[0]?.fee : item?.init_fee
            return item;
        })
        this.setState({ renderList: checkRequestDetails })
    }

    calculateCheckRequestShare = (share) => {
        let checkRequestDetails = this.state.renderList?.length > 0 ? [...this.state.renderList] : [];
        checkRequestDetails?.map(item => {
            item.share = parseInt(share) / parseInt(item?.lic_per);
            return item;
        })
        this.setState({ renderList: checkRequestDetails })
    }

    handleOpenLicensorInlineModals = (dataItem, nodeName, rowIndex, event) => {
        if (nodeName === "inv") {
            this.setState({
                setInvModal: event.currentTarget,
                checkRequestInv: {
                    "inv": dataItem?.inv,
                    "invItemIndex": rowIndex,
                    "clearance_check_request_id": !this.props?.isMainTitle ? dataItem?.clearance_check_request_id : null,
                    "main_title_check_request_id": !this.props?.isMainTitle ? null : dataItem?.clearance_check_request_id
                }
            })
        } else if (nodeName === "eps_cnt") {
            this.setState({
                eps_cnt_list: dataItem?.episodes_list,
                setEpsCountModal: event.currentTarget,
                epsCountFlag: rowIndex
            })
        }
        else {
            this.setState({
                setNotesModal: event?.currentTarget || event,
                checkRequestNotes: {
                    "notes": dataItem?.notes,
                    "notesItemIndex": rowIndex,
                    "clearance_check_request_id": !this.props?.isMainTitle ? dataItem?.clearance_check_request_id : null,
                    "main_title_check_request_id": !this.props?.isMainTitle ? null : dataItem?.clearance_check_request_id
                }
            })
        }
    }

    handleOpenOptionalPayeeInlineModals = (dataItem, nodeName, rowIndex, event) => {
        if (nodeName === "inv") {
            this.setState({
                setOpInvModal: event.currentTarget,
                optionalPayeeInv: {
                    "inv": dataItem?.inv,
                    "invItemIndex": rowIndex,
                    "clearance_licensor_optional_rights_id": !this.props?.isMainTitle ? dataItem?.clearance_licensor_optional_rights_id : null,
                    "clearance_licensor_optional_check_requests_id": !this.props?.isMainTitle ? dataItem?.clearance_licensor_optional_check_requests_id : null,
                    "main_title_licensor_optional_rights_id": !this.props?.isMainTitle ? null : dataItem?.main_title_licensor_optional_rights_id,
                    "main_title_licensor_optional_check_requests_id": !this.props?.isMainTitle ? null : dataItem?.main_title_licensor_optional_check_requests_id,
                }
            })
        }
        else {
            this.setState({
                setOpNotesModal: event?.currentTarget || event,
                optionalPayeeNotes: {
                    "notes": dataItem?.notes,
                    "notesItemIndex": rowIndex,
                    "clearance_licensor_optional_rights_id": !this.props?.isMainTitle ? dataItem?.clearance_licensor_optional_rights_id : null,
                    "clearance_licensor_optional_check_requests_id": !this.props?.isMainTitle ? dataItem?.clearance_licensor_optional_check_requests_id : null,
                    "main_title_licensor_optional_rights_id": !this.props?.isMainTitle ? null : dataItem?.main_title_licensor_optional_rights_id,
                    "main_title_licensor_optional_check_requests_id": !this.props?.isMainTitle ? null : dataItem?.main_title_licensor_optional_check_requests_id,
                }
            })
        }
    }

    handleCRPopUpOnChange = (value, listName, index) => {
        let checkRequests = [...this.state.lic[listName]];
        let isVoided = false
        let updatedCheckRequest = checkRequests?.map(item => {
            let newItem = {...item}
            if (value[listName + '_id'] ? item[listName + '_id'] === value[listName + '_id'] : item?.id === value?.id) {
                newItem.check = value?.check;
                newItem.date_paid = value?.date_paid;
                newItem.is_exclude_batch = value?.is_exclude_batch ? 1 : 0;
                newItem.is_void = value?.is_void ? 1 : 0;
                newItem.is_amort = value?.is_amort ? 1 : 0; 
                if(value?.notes)    {
                    newItem.notes= item?.notes ? item?.notes + value?.notes : value?.notes
                    value.notes = item.notes
                }
                if(item?.is_void && value?.notes){
                    isVoided = true;
                }
            }
            return newItem;
        })
        is_check_request_updated = 1;
        this.setState({renderList:updatedCheckRequest})
        this.onStateLicChange(listName, updatedCheckRequest);
        if(isVoided){
            let elementId = value[listName + '_id'] ? value[listName + '_id'] : value?.id
            let ele = document.getElementById(`${'notes_'+elementId+"_"+this.state?.selectedChkReqIndex}`)
            // ele.click();
            this.handleOpenLicensorInlineModals(value,'cr_notes',this.state?.selectedChkReqIndex,ele)
        }
    }

    handleVoidCheck = () => {
        this.setState({
            showVoidCheckWarning: true,
            voidCheckError: "You cannot void a check unless a Pay Date and Checknumber exist."
        })
    }

    onStateLicChange = (field, value, index, field2) => {
        if (field === "MasterLicensorData") {
            this.setState(prevState => ({
                lic: {
                    ...prevState.lic,
                    ...value
                },
                isFieldChanged: true
            }), () => { this.props?.fieldChanged(true, "change") })
        }
        else {
            this.setState(prevState => ({
                lic: {
                    ...prevState.lic,
                    [field]: value
                },
                isFieldChanged: true
            }), () => {
                this.props?.fieldChanged(true, "change")
                if(field === "status_id") {
                    updated_status_id = value;
                    updated_status_name = this.state.status?.find(item => item.value === value)?.label
                    this.setState({ statusChangeAlert: true })
                }
                if (field === "share") {
                    this.validateSharePercentage(value);
                }
                if (field === "rights" && index == 0 && field2 === "fee") {
                    this.setChkReqOnMainShareFeeChanged(true);
                }
            })
        }

    }
    setChkReqOnMainShareFeeChanged(fromFee) {
        let checkRequestData = this.state?.renderList?.length > 0 ? JSON.parse(JSON.stringify(this.state?.renderList)) : [];
        let optionalRightKey = !this.props?.isMainTitle ? 'clearance_licensor_optional_rights' : 'main_title_licensor_optional_rights';
        let optionalRightCheckRequestKey = !this.props?.isMainTitle ? 'clearance_licensor_optional_check_requests' : 'main_title_licensor_optional_check_requests';
        let optionalRights = this.state?.lic?.[optionalRightKey] ? JSON.parse(JSON.stringify(this.state?.lic?.[optionalRightKey])) : []
        if (checkRequestData?.length > 0) {
            checkRequestData = checkRequestData?.map(o => {
                if (o?.lic_per) {
                    let share = this.calculateLicShareAmount(o?.lic_per);
                    let initFee = this.calculateLicShareAmount(o?.lic_per, share);
                    o.share = !isNaN(share) ? share : o.share;
                    o.init_fee = !isNaN(initFee) ? initFee : o.init_fee
                }
                return o;
            });
            is_check_request_updated = 1
            this.setState({ renderList: checkRequestData });
        }
        if (!fromFee) {
            optionalRights = optionalRights?.map(item => {
                let newItem = { ...item };
                newItem[optionalRightCheckRequestKey] = newItem?.[optionalRightCheckRequestKey]?.map(obj => {
                    let chkData = { ...obj }
                    let share = this.calculateLicShareAmount(chkData?.lic_per);
                    let initFee = this.calculateLicShareAmount(chkData?.lic_per, share);
                    chkData.share = !isNaN(share) ? share : chkData.share;
                    chkData.init_fee = !isNaN(initFee) ? initFee : chkData.init_fee
                    return chkData;
                });
                return newItem;
            })
            this.onStateLicChange(optionalRightKey, optionalRights)
            this.setState((prevState) => ({
                optionalChkReqUpdated: !prevState.optionalChkReqUpdated
            })
            )
        }

    }
    handleAddOptionFeeToCheckRequest = (optionalRight, isInclInit) => {
        let checkRequestKey = !this.props?.isMainTitle ? "clearance_check_request" : "main_title_check_request";
        let checkRequestDetails = [...this.state.lic[checkRequestKey]];
        let checkRequestConfig = this.state.config;
        if (isInclInit) {
            let heading = {
                // headingLabel: `${optionalRight?.right_abbr.substring(0, 10)}…`,
                headingLabel: optionalRight?.right_abbr,
                inputType: 'text',
                border: true,
                width: '10%',
                rightId: optionalRight?.right_id
            }
            checkRequestConfig?.headings?.splice((checkRequestConfig?.headings?.length - 4), 0, heading);
            checkRequestConfig?.dataNodes?.splice((checkRequestConfig?.dataNodes?.length - 4), 0, ('optional_right_fee' + '_' + optionalRight?.right_id));
            // let inlineEditObj = {
            //     dataNode: `optional_right_fee_${optionalRight?.right_id}`,
            //     width: '10%'
            // }
            // checkRequestConfig?.inlineEdits?.splice((checkRequestConfig?.inlineEdits?.length - 4), 0, inlineEditObj);
            checkRequestDetails?.map(item => {
                item['optional_right_fee' + '_' + optionalRight?.right_id] = item?.lic_per ? (parseInt(optionalRight?.fee) / parseInt(item?.lic_per)) :
                    optionalRight?.fee;
                item['optional_right_fee' + '_' + optionalRight?.right_id + '_id'] = optionalRight?.right_id;
                return item;
            })
            this.setState({ renderList: checkRequestDetails })
        } else {
            let checkReqHeading = checkRequestConfig?.headings?.filter(item => item?.rightId !== optionalRight?.right_id);
            let filteredDatNodes = checkRequestConfig?.dataNodes?.filter(item => item !== ('optional_right_fee' + '_' + optionalRight?.right_id))
            checkRequestConfig.headings = [...checkReqHeading]
            checkRequestConfig.dataNodes = [...filteredDatNodes]
            checkRequestDetails?.map(item => {
                if (item['optional_right_fee' + '_' + optionalRight?.right_id + '_id'] === optionalRight?.right_id) {
                    delete item.right_abbr_fee;
                    return item;
                }
            })
            this.setState({
                config: checkRequestConfig,
                renderList: checkRequestDetails
            })
        }
    }

    getLicensorData = (searchString = null) => {
        if (this.state.searchCancelToken != null)
            this.state.searchCancelToken.cancel("Operation canceled due to new request");
        var cancelToken = axios.CancelToken.source();
        this.setState({ searchCancelToken: cancelToken });
            clearTrackService.getDataWithCancel(Constants.ClearTrackServiceBaseUrl +
                `/entitySearch?entity=LICENSOR&searchString=${searchString}`,cancelToken ,this.props.userContext?.active_tenant?.tenant_id)
                .then(response => {
                    let formattedList = response.data?.map(item => ({ value: item.licensor_id, text: item.licensor_name }));
                    this.setState({ licensorData: formattedList , isLicensorFetching:false});
                },
                    (err) => {
                        console.log("Error in fetching Address Types:", err);
                        this.setState({ isFetchingClearanceDetails: false,isLicensorFetching:false });
                    })
    }


    getLicensorRepData = (entity, list, searchString) => {
        let allUsers = []
        if (this.props?.userContext?.active_tenant?.tenant_id) {
            this.setState({ isLicenseeRepFetching: true })
            clearTrackService.getData(Constants.ClearTrackServiceBaseUrl +  '/userAll', this.props.userContext?.active_tenant?.tenant_id)
                .then(response => {
                    allUsers = [...response?.data]
                    let validRoles = ['Super Admin', 'Music Clearance (Admin)', 'Music Clearance (Regular User)', 'Licensing Admin']
                    let validUsers = []
                    for(let i = 0; i< response?.data?.length; i++) {
                    for(let j = 0; j < response?.data[i]?.tenants?.length ;  j++) {
                        if(response?.data[i]?.tenants[j]?.tenant_id === this.props.userContext?.active_tenant?.tenant_id) {
                        for(let k = 0; k < response?.data[i]?.tenants[j]?.division_role?.length ;  k++) { 
                            if(response?.data[i]?.tenants[j]?.division_role[k]?.division_id === this.props.division_id) {
                            if(validRoles?.includes(response?.data[i]?.tenants[j]?.division_role[k]?.role_name)) {
                                validUsers.push({
                                    value:  response?.data[i].user_id,
                                    label:  response?.data[i].full_name,
                                    is_active: response?.data[i].is_active,
                                })
                            }
                            }
                        }
                        }
                    }
                    }
                    if(this.state.lic?.licensee_rep_id && !validUsers?.find(item => item?.value === this.state.lic?.licensee_rep_id)) {
                        validUsers.push({
                            value:  this.state.lic?.licensee_rep_id,
                            label:  this.state.lic?.licensee_rep_name,
                            is_active: allUsers?.find(item => item?.user_id === this.state.lic?.licensee_rep_id )?.is_active,
                        })
                    }
                    this.setState({ licensorRepOptions: validUsers, isLicenseeRepFetching: false });
                },
                    (err) => {
                        console.log("Error in fetching licensor data:", err);
                    })
        }
    }

    getRightsData = () => {
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=RIGHTS&searchString=null`,
            this.props?.userContext?.active_tenant?.tenant_id, this.props?.division_id)
            .then(response => {
                let initialRights = response.data?.filter((item) => item.is_initial_right === 1 || item?.can_be_included_with_init_right === 1)
                let altInitialRights = response.data?.filter((item) => item.can_be_alt_init_right === 1)
                let formattedListRights = initialRights?.map(item => ({ value: item.right_id, label: item.right_abbr }));
                let formattedListAltInitRights = altInitialRights?.map(item => ({ value: item.right_id, label: item.right_abbr }));
                let optionalRights = response.data?.filter((item) => item.is_optional === 1)
                let formatOptionalRights = optionalRights?.map(item => ({ value: item.right_id, label: item.right_abbr, default_term_id: item.default_term_id, default_term_name: item.default_term_name, default_territory_id: item.default_territory_id, default_territory_abbr: item.default_territory_abbr, default_exp_term_id: item.default_exp_term_id, default_exp_term_name: item.default_exp_term_name }));
                let r = this.state.optionalRightsConfig
                r.headings.map((item) => {
                    if (item.inputType === "select" && item.headingLabel === "Option") {
                        item.selectOptions = formatOptionalRights
                    }
                })
                this.setState({ allrightsofsystem: response?.data, rights: formattedListRights, altInitRights: formattedListAltInitRights, optionalRightsConfig: r });
            },
                (err) => {
                    console.log("Error in fetching Address Types:", err);
                    this.setState({ isFetchingClearanceDetails: false });
                })
    }

    getTerritoryData = () => {
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=TERRITORY&searchString=null`,
            this.props?.userContext?.active_tenant?.tenant_id, this.props?.division_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.territory_id, label: item.territory_abbr }));
                this.setState({ territory: formattedList });
                let z = this.state.optionalRightsConfig
                z.headings.map((item) => {
                    if (item.inputType === "select" && item.headingLabel === "Territory") {
                        item.selectOptions = formattedList
                    }
                })
                this.setState({
                    optionalRightsConfig: z
                })
            },
                (err) => {
                    console.log("Error in fetching Address Types:", err);
                    this.setState({ isFetchingClearanceDetails: false });
                })
    }

    getTermData = () => {
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=TERM&searchString=null`,
            this.props?.userContext?.active_tenant?.tenant_id, this.props?.division_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.term_id, label: item.term_name }));
                let z = this.state.optionalRightsConfig
                z.headings.map((item) => {
                    if (item.inputType === "select" && (item.headingLabel === "Term" || item.headingLabel === "Opt Exp")) {
                        item.selectOptions = formattedList
                    }
                })
                this.setState({ optionalRightsConfig: z, term: formattedList });
            },
                (err) => {
                    console.log("Error in fetching Address Types:", err);
                    this.setState({ isFetchingClearanceDetails: false });
                })
    }
    getDefaultPayee = () =>{
 
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=DEFAULT_LICENSOR_PAYEES&searchString=${this.state.lic?.licensor_id}`,
        this.props?.userContext?.active_tenant?.tenant_id, this.props?.division_id)
        .then(response => {
            let formattedList = response.data?.map(item => ({ value: item.default_payee_remit_to_id, text: item.default_payee_contact_name }));
            this.setState({defaultAllPayee:formattedList})
            this.getPayeeData();
    
        },
        (err) => {
            console.log("Error in fetching Default Payee:", err);
            this.setState({ isFetchingClearanceDetails: false });
            this.setState({defaultAllPayee:[]})
        })
    }
    getPayeeData =  () => {
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=PAYEE_REMIT_TO&searchString=null`,
            this.props?.userContext?.active_tenant?.tenant_id, this.props?.division_id)
            .then((response) => {
                let formattedList = response.data?.map(item => ({ value: item.payee_remit_to_id, text: item.payee }));
              
                if(this.state?.defaultAllPayee?.length > 0){
                    formattedList = [...this.state?.defaultAllPayee,...formattedList]
                }               
                let r = this.state.config
                r.headings.map((item) => {
                    if (item.inputType === "searchselect" && item.headingLabel === "Payee") {
                        item.selectOptions = formattedList
                    }
                })
                let z = this.state.CueSheetPublisherConfig
                z.headings.map((item) => {
                    if (item.inputType === "searchselect" && item.headingLabel === "Payee") {
                        item.selectOptions = formattedList
                    }
                })
                this.setState({
                    config: r,
                    CueSheetPublisherConfig: z,
                    allPayeeRemitTo: response.data?.map(item => ({ payee: item?.payee_id, value: item.payee_remit_to_id, text: item.payee }))
                }, () => {
                    let licDetails = { ...this.state.lic };
                    let checkRequestKey = !this.props?.isMainTitle ? 'clearance_check_request' : 'main_title_check_request';
                    let optionalRightKey = !this.props?.isMainTitle ? 'clearance_licensor_optional_rights' :
                        'main_title_licensor_optional_rights';
                    let optionalRequestCheckReqKey = !this.props?.isMainTitle ? 'clearance_licensor_optional_check_requests' :
                        'main_title_licensor_optional_check_requests';
                    licDetails?.[checkRequestKey]?.map(item => {
                        item.id = !this.props?.isMainTitle ? item.clearance_check_request_id : item.main_title_check_request_id;
                        item['payee_remit_to_name'] = this.state.config?.headings?.[1]?.selectOptions?.find(o => o.value === (item?.payee_remit_to?.value || item?.payee_remit_to))?.text;
                        item['in_batch'] = item?.['in_batch'] ? item?.['in_batch'] : 'No';
                        if (this.props?.isMainTitle && item.editing) {
                            item.payee_remit_to['text'] = item.payee_remit_to_name
                        }
                        return item;
                    })
                    licDetails?.[optionalRightKey]?.map(item => {
                        item.id = !this.props?.isMainTitle ? item?.clearance_licensor_optional_rights_id : item?.main_title_licensor_optional_rights_id;
                        item?.[optionalRequestCheckReqKey]?.map(optionalPayee => {
                            optionalPayee[optionalRightKey + "_id"] = item[optionalRightKey + "_id"];
                            optionalPayee.id = !this.props?.isMainTitle ? optionalPayee?.clearance_licensor_optional_check_requests_id : optionalPayee?.main_title_licensor_optional_check_requests_id;
                            optionalPayee['payee_remit_to_name'] = this.state.CueSheetPublisherConfig?.headings?.[1]?.selectOptions?.find(o => o.value === (optionalPayee?.payee_remit_to?.value || optionalPayee?.payee_remit_to))?.text;
                            //optionalPayee['in_batch'] = optionalPayee?.['in_batch'] == 0 ? "No" : null;
                            optionalPayee['bch'] = 1
                            if (this.props?.isMainTitle && optionalPayee.editing) {
                                optionalPayee.payee_remit_to['text'] = optionalPayee.payee_remit_to_name
                            }
                            return optionalPayee;
                        })
                        return item;
                    })
                    this.setState({ lic: licDetails })
                })
            },
                (err) => {
                    console.log("Error in fetching Address Types:", err);
                    this.setState({ isFetchingClearanceDetails: false });
                })
    }

    check = (listEdit) => {
        let checkRequestKey = !this.props?.isMainTitle ? "clearance_check_request" : "main_title_check_request";
        this.props.handleListEdit(checkRequestKey, listEdit, this.state.lic, 'licensors_list');
    }

    formatPostJson = (type) => {
        let chkReqKeyId = this.props?.isMainTitle ? "main_title_check_request_id" : "clearance_check_request_id";
        let rightId = this.props?.isMainTitle ? "main_title_licensor_rights_id" : "clearance_licensor_rights_id";
        let renderList = this.state?.renderList?.map(item => {
            // item.payee_remit_to_id === null ? item.payee_remit_to_id = item.payee_remit_to?.value : null  
            item.payee_remit_to_id = item.payee_remit_to_id === null ? item.payee_remit_to?.value : item.payee_remit_to_id;
            item[chkReqKeyId] = type === "copy" ? null : item?.[chkReqKeyId];
            item.requested_date = item?.requested_date ? new Date(item?.requested_date).toISOString() : null;
            item.date_paid = item?.date_paid ? new Date(item?.date_paid).toISOString() : null;
            item.share = item?.share ? parseFloat(item?.share)?.toFixed(3) : item?.share;
            if (this.props?.isMainTitle) {
                item.check = item?.check ? item?.check : null;
                item.eps_cnt = item?.eps_cnt ? item?.eps_cnt : 0;
                item.episodes_list = item?.episodes_list?.length > 0 ? [...item?.episodes_list] : [];
                item.is_cr_eps_updated = item?.is_cr_eps_updated ? item?.is_cr_eps_updated : 0;
            }
            return item
        })
        let rights = [...this.state.lic?.rights];
        let optionalRights = !this.props?.isMainTitle ? this.state.lic?.clearance_licensor_optional_rights?.length > 0 ? [...this.state.lic?.clearance_licensor_optional_rights] : [] :
            this.state.lic?.main_title_licensor_optional_rights?.length > 0 ? [...this.state.lic?.main_title_licensor_optional_rights] : [];
        let optionalRequestCheckReqKey = !this.props?.isMainTitle ? 'clearance_licensor_optional_check_requests' :
            'main_title_licensor_optional_check_requests';

        optionalRights?.map(op => {
            op.clearance_licensor_optional_rights_id = type === 'copy' ? null : op.clearance_licensor_optional_rights_id;
            op?.[optionalRequestCheckReqKey]?.map(cr => {
                cr.payee_remit_to_id = cr.payee_remit_to?.value;
                cr.share = cr?.share ? parseFloat(cr?.share)?.toFixed(3) : cr?.share;
                cr.clearance_licensor_optional_check_requests_id = type === 'copy' ? null : cr.clearance_licensor_optional_check_requests_id;
                return cr;
            })
            return op;
        })

        //push in_active check requests into original list
        let checkReqTemp = []
        let optionalRightTemp = []
        let optionalRightCheckReqTemp = []
        //check request post update
        this.state.inActiveCheckRequests?.map(item => {
            if (item?.chkReqKeyId) {
                checkReqTemp?.push(item);
            }
        });
        renderList?.map(item => {
            checkReqTemp?.push(item);
        });
        renderList = [...checkReqTemp];
        // renderList.map(item=>{
        //     item.payee_remit_to_id = item.payee_remit_to_id === null ? item.payee_remit_to?.value : null;
        //     return item;
        // })
        let optionalRightKeyId = !this.props?.isMainTitle ? "clearance_licensor_optional_rights_id" : "main_title_licensor_optional_rights_id";
        let optionalRightChkRequestKeyId = !this.props?.isMainTitle ? "clearance_licensor_optional_check_requests_id" : "main_title_licensor_optional_check_requests_id";

        //optional right post update
        this.state.inActiveOptionalRights?.map(item => {
            if (item?.[optionalRightKeyId]) {
                optionalRightTemp?.push(item);
            }
        });
        optionalRights?.map(item => {
            optionalRightTemp?.push(item);
        });
        optionalRights = [...optionalRightTemp];

        //optional right check request post update
        this.state.inActiveOptionalRightCheckRequest?.map(item => {
            if (item?.[optionalRightChkRequestKeyId]) {
                optionalRightCheckReqTemp?.push(item);
            }
        });
        optionalRights?.[this.state.inActiveOptionalRightIndex]?.[optionalRequestCheckReqKey]?.map(item => {
            optionalRightCheckReqTemp?.push(item);
        });
        if (this.state.inActiveOptionalRightIndex !== null && this.state.inActiveOptionalRightIndex !== undefined) {
            optionalRights[this.state.inActiveOptionalRightIndex][optionalRequestCheckReqKey] = [...optionalRightCheckReqTemp];
        }
        if (type === 'copy') {
            // optionalRights = optionalRights?.map(op => {
            //     op.is_licensor_optional_check_requests_updated = op[optionalRequestCheckReqKey]?.length > 0 ? 1 : 0;
            //     op[optionalRequestCheckReqKey]?.map(cr => cr.clearance_licensor_optional_check_requests_id = null)
            //     return op;
            // })
            rights = rights?.map(r => { r[rightId] = null; return r })
        }
       
        optionalRights = optionalRights?.map(op => {
            op.option_acquired_date = op?.option_acquired_date ? new Date(op?.option_acquired_date).toISOString() : null;
            if(type === 'copy'){
                op[optionalRightKeyId] = null
                op.is_licensor_optional_check_requests_updated = op[optionalRequestCheckReqKey]?.length > 0 ? 1 : 0;
            }
            op[optionalRequestCheckReqKey] = op?.[optionalRequestCheckReqKey]?.map(cr => {
                cr['payee_remit_to_id'] = cr.payee_remit_to?.value || cr?.payee_remit_to;
                if(type === 'copy'){
                    cr[optionalRightChkRequestKeyId] = null
                }
                return cr;
            })
            op.incl_init = op?.incl_init == true ? 1 : 0;
            return op;
        })
        rights = rights?.map((i)=>{
            i.fee =  i?.fee == "" ? null : i?.fee; 
            return i;
        })
        //if(this.props?.isMainTitle){
            rights = rights?.filter(i=> i.is_initial || (i.is_alt_initial == 1 && i.right_id ));
        //}
        let postJSON = {
            "show_season_id": this.props?.showSeasonId,
            "show_id": this.props?.showId,
            "season_id": this.props?.seasonId,
            "show_season_episode_id": this.props?.showSeasonEpisodeId,
            "licensor_id": this.state.lic?.licensor_id,
            // "licensor_type": this.state.lic?.licensor_type,
            "licensor_type": this.props?.selectLicensorType?.find(item => item.value === this.state.lic?.licensor_type)?.label || this.state.lic?.licensor_type,
            "share": this.state.lic?.share,
            "status_id": this.state.lic?.status_id,
            "is_they_provide_license": this.state.lic?.is_they_provide_license,
            "selected_licensee_rep": this.state.lic?.selected_licensee_rep,
            "licensee_rep_id": this.state.lic?.licensee_rep_name,
            "licensee_rep_id": this.state.lic?.licensee_rep_id,
            "licensee_rep_name": this.state.lic?.licensee_rep_name,
            "notes": this.state.lic?.notes,
            "is_mfn_with_S_M": this.state.lic?.is_mfn_with_S_M,
            "is_mfn_with_M": this.state?.lic?.is_mfn_with_M,
            "is_mfn_with_Co_M": this.state?.lic?.is_mfn_with_Co_M,
            "is_mfn_with_Pub": this.state?.lic?.is_mfn_with_Pub,
            "is_mfn_with_Co_Pub": this.state?.lic?.is_mfn_with_Co_Pub,
            "is_active": this.state.lic?.is_active,
            "is_check_request_updated": type === "copy" ? renderList?.length > 0 ? 1 : 0 : is_check_request_updated,// need to be checked and updated
            "is_licensor_optional_rights_updated": type === "copy" ? optionalRights?.length > 0 ? 1 : 0 : this.state.isLicensorOptionalRightsUpdated,
            "is_rights_updated": type === "copy" ? this.state.lic?.rights?.length > 0 ? 1 : 0 : this.state.lic?.rights?.length > 0 ? is_rights_updated : 0,//// need to be checked and updated
            "rights": rights,
            "quote_request_date": this.state.lic?.quote_request_date ? new Date(this.state.lic?.quote_request_date).toISOString() : null,
            "confirmation_date": this.state.lic?.confirmation_date ? new Date(this.state.lic?.confirmation_date).toISOString() : null,
            "use_confirmation_date": this.state.lic?.use_confirmation_date ? new Date(this.state.lic?.use_confirmation_date).toISOString() : null,
            "license_request_date": this.state.lic?.license_request_date ? new Date(this.state.lic?.license_request_date).toISOString() : null,
            "is_delete": type === "delete" ? 1 : 0,
            "letter_type":"voidedCheckNotification"
        }
        if (!this.props?.isMainTitle) {
            postJSON['clearance_licensor_id'] = type === "copy" ? null : this.state.lic?.clearance_licensor_id;
            postJSON['clearance_id'] = this.props?.clearenceId;
            postJSON['is_apply_dollar_to_HV'] = this.state.lic?.is_apply_dollar_to_HV;
            postJSON['is_license_for_sample'] = this.state.lic?.is_license_for_sample,
                postJSON['recording_share'] = this.state.lic?.recording_share;
            postJSON['clearance_check_request'] = renderList?.filter(item => !(item.is_delete === 1 && !item[chkReqKeyId]));
            postJSON['clearance_licensor_optional_rights'] = optionalRights.map(item => {
                if (item.is_delete === 1 && !item[optionalRightKeyId]) {
                    return null
                } else {
                    item[optionalRequestCheckReqKey]?.map(ele => {
                        if (ele.is_delete === 1 && !ele[optionalRightChkRequestKeyId]) {
                            return null
                        } else {
                            return ele
                        }
                    })
                    return item
                }
            });
        } else {
            postJSON['is_apply_dollar_to_episodic_budget'] = this.state.lic?.is_apply_dollar_to_episodic_budget;
            postJSON['is_broad_use_license'] = this.state.lic?.is_broad_use_license;
            postJSON['is_flat_fee_buyout'] = this.state.lic?.is_flat_fee_buyout;
            postJSON['is_allow_zero_cost'] = this.state.lic?.is_allow_zero_cost;
            postJSON['is_WFH'] = this.state.lic?.is_WFH;
            postJSON['contract_date'] = this.state.lic?.contract_date;
            postJSON['addendum_date'] = this.state.lic?.addendum_date;
            postJSON['main_title_licensor_id'] = type === "copy" ? null : this.state.lic?.main_title_licensor_id;
            postJSON['main_title_id'] = this.props.licensorsInfo?.[0]?.main_title_id || this.props?.mainTitleId;
            postJSON['main_title_song_id'] = this.props?.mainTitleSongId;
            postJSON['main_title_check_request'] = renderList?.filter(item => !(item.is_delete === 1 && !item[chkReqKeyId]));
            postJSON['main_title_licensor_optional_rights'] = optionalRights.map(item => {
                if (item.is_delete === 1 && !item[optionalRightKeyId]) {
                    return null
                } else {
                    item[optionalRequestCheckReqKey]?.map(ele => {
                        if (ele.is_delete === 1 && !ele[optionalRightChkRequestKeyId]) {
                            return null
                        } else {
                            return ele
                        }
                    })
                    return item
                }
            });
        }
        return postJSON;
    }
    validatePostJson(postJSON) {
        let isValid = false
        if (!this.props?.isMainTitle) {
            isValid = postJSON?.clearance_id && postJSON?.show_season_id &&
                postJSON?.show_id && postJSON?.season_id && postJSON?.show_season_episode_id &&
                (postJSON?.share || postJSON?.share == 0) &&
                postJSON?.licensor_id && postJSON?.licensor_type && postJSON?.status_id &&
                //postJSON?.licensee_rep_id &&
                postJSON?.rights?.filter(i=> i.is_initial == 1)?.every(o => o?.right_id && o?.term_id && o?.territory_id)
        }
        else {
            isValid = postJSON?.licensor_id && postJSON?.licensor_type && postJSON?.status_id &&
               (postJSON?.share || postJSON?.share == 0) &&
               // postJSON?.licensee_rep_id &&
                postJSON?.rights?.filter(i=> i.is_initial == 1)?.every(o => o?.right_id && o?.term_id && o?.territory_id)

        }
        let chkReqKey = !this.props?.mainTitle ? "clearance_check_request" : "main_title_check_request";
        let optionalReqKey = !this.props?.mainTitle ? "clearance_licensor_optional_rights" : "main_title_licensor_optional_rights";
        let optionalChkReqKey = !this.props?.mainTitle ? "clearance_licensor_optional_check_requests" : "main_title_licensor_optional_check_requests";
        let isValidChkReq = (postJSON?.[chkReqKey]?.length > 0 ?
            postJSON?.[chkReqKey]?.filter(o => !o?.is_delete)?.every(o => o?.payee_remit_to_id && (o?.lic_per === 0 ? true : o?.lic_per) &&
                (o?.share === 0 ? true : o?.share) && (o?.init_fee === 0 ? true : o?.init_fee) && validateNumDotWithNegative(o?.lic_per) && validateNumDotWithNegative(o?.share) && validateNumDotWithNegative(o?.init_fee)) : true)

        let isValidOptionalReq = (postJSON?.[optionalReqKey]?.length > 0 ?
            postJSON?.[optionalReqKey]?.filter(o => !o?.is_delete && o.is_delete!==undefined)?.every(o => o?.right_id && o?.fee && o?.term_id && o?.territory_id && o?.opt_exp_term_id && validateNumDotWithNegative(o?.fee)) : true);


        let isValidOptionalRightChkReq = (postJSON?.[optionalReqKey]?.length > 0 ?
            postJSON?.[optionalReqKey]?.filter(o => !o?.is_delete)?.every(item => item?.[optionalChkReqKey]?.length > 0 ?
                item?.[optionalChkReqKey]?.filter(o => !o?.is_delete)?.every(o => o?.payee_remit_to_id && (o?.lic_per === 0 ? true : o?.lic_per) &&
                    (o?.share === 0 ? true : o?.share) && (o?.fee === 0 ? true : o?.fee) && validateNumDotWithNegative(o?.lic_per) && validateNumDotWithNegative(o?.share) && validateNumDotWithNegative(o?.fee)) : true) : true)
        if (!isValidChkReq) {
            this.setState({ inValidChkReq: true })
        }
        else {
            this.setState({ inValidChkReq: false })
        }
        if (!isValidOptionalReq) {
            this.setState({ inValidOptionalReq: true })
        }
        else {
            this.setState({ inValidOptionalReq: false })
        }
        if (!isValidOptionalRightChkReq) {
            this.setState({ inValidOptionalChkReq: true })
        }
        else {
            this.setState({ inValidOptionalChkReq: false })
        }


        return (isValid && isValidChkReq && isValidOptionalReq && isValidOptionalRightChkReq) || false;



    }
    postLicensorData = (type = null) => {
        let postJSON = this.formatPostJson(type);
        let isValid = this.validatePostJson(postJSON);
        if (!isValid) {
            this.setState({ postLicensorValidate: true })
            if(!this.state?.lic?.editing){
                this.props?.setMode();
            }
            return;
        }
        // check for adjustment in check request
        if (postJSON.is_check_request_updated && !this.state?.isDoneAutoAdjustPopup) {
            let checkRequestKey = !this.props?.isMainTitle ? "clearance_check_request" : "main_title_check_request";
            let totalChkFee = postJSON?.[checkRequestKey]?.reduce((acc, o) => { return !o?.is_delete && o?.init_fee && parseFloat(o?.init_fee) ? (acc + parseFloat(o?.init_fee)) : (acc + 0) }, 0)
            if (totalChkFee > parseFloat(this.state?.lic?.rights?.[0]?.fee)) {
                // show popup
                this.setState({ showChkReqAutoAdjust: true, isDoneAutoAdjustPopup: true })
                return;
            }
        }
        this.setState({ postLicensorValidate: false })
        let url;
        if (!this.props?.isMainTitle) {
            this.setState({ postingLicensor: true })
            url = `/clearanceLicensors`;
        } else {
            this.setState({ postingMainTitleLicensor: true })
            url = `/mainTitleLicensors`;
        }
        clearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + url, postJSON, this.props?.userContext?.active_tenant?.tenant_id, this.props?.division_id)
            .then((response) => {
                if (!response.data.error) {
                    this.notificationComponent(true, "success");
                    this.setState({
                        postingLicensor: false,
                        postingMainTitleLicensor: false,
                        // renderList: this.state.renderList,
                        isDoneAutoAdjustPopup: false
                    })
                    setTimeout(() => {
                        if (!this.props?.isMainTitle) {
                            this.props?.toggleRefreshPage('EpisodeClearance')
                        } else {
                            this.props?.toggleRefreshMainTitleLicensor()
                        }
                    }, 1000);
                    if (this.state.changeStatusOfAllLicensors) {
                        let licensorId = this.props?.isMainTitle ? this.state?.lic?.main_title_licensor_id : this.state?.lic?.clearance_licensor_id
                        this.props?.changeStatusOfLicensors(updated_status_id, updated_status_name)
                    }
                    // console.log(response);
                } else {
                    this.setState({ postingLicensor: false, postingMainTitleLicensor: false, })
                    this.notificationComponent(true, "fail");
                }

            }, (err) => {
                this.setState({ postingLicensor: false, postingMainTitleLicensor: false, })
                this.notificationComponent(true, "fail");
                console.log("Failed");
            }).finally(() => {
                is_check_request_updated = 0;
                is_licensor_optional_rights_updated = 0;
                is_rights_updated = 0;
            })
    }
    notificationComponent = (value, mode = "fail") => {
        switch (mode) {
            case "success": return this.setState({ showSuccess: value, saveFlag: true });
            case "fail": return this.setState({ showFail: value });
        }
    }

    handleEditPayeeModal = (node,dataItem,index) => (e) => {
        this.setState({ openPayeeModal: dataItem?.payee_id })
    }

    handleResetNotify = () => {
        this.setState({ showFail: false, showSuccess: false });
        if (this.state.saveFlag) {
            this.setState({ saveFlag: false }, this.closPopup)
        }
    }

    closPopup = () => {
        this.setState({ showError: false, showFail: false, showSuccess: false });
        // this.props?.handleClose();
    }

    onLicensorDropdownOptionClick = (option, licensorOptType) => {
        // if (addRemoveChangeLicensor) {
            if (option === "Edit Licensor") {
                this.handleshowEditLicensorModal("Edit")
            } else if (option === "Add New Licensor to this CL Record") {
                // this.handleshowEditLicensorModal("New")
                this.props?.addNewLicensorInfo();
            } else if (option === "Change/View Default Contact") {
                this.handleshowContactModal()
            } else if (option === "Copy Licensor on this Cl Record") {
                // this.props?.handleCopyLicensor(this.state.lic?.clearance_licensor_id)
                this.postLicensorData('copy');
            } else if (option === "Remove Licensor from this Cl R") {
                this.postLicensorData('delete');
            } else if(option === "Request W9-W8-VEN") {
                this.postEmailRequest()
            } 
            else if (option === "status_id") {
                let licensorId = this.props?.isMainTitle ? this.state?.lic?.main_title_licensor_id : this.state?.lic?.clearance_licensor_id
                this.props?.changeStatusOfLicensors(option, licensorOptType, updated_status_name, licensorId)
            } 
            else {
                let licensorId = this.props?.isMainTitle ? this.state?.lic?.main_title_licensor_id : this.state?.lic?.clearance_licensor_id
                this.props?.calcFeesBasedOnSyncMaster(option, licensorOptType, licensorId)
            }
        // }
        this.handleClose();

    }
    postEmailRequest=()=>{
        this.setState({isSubmit: true})
       let postObj = {
        "show_season_episode_id": this.props?.showSeasonEpisodeId,
        "licensor_id": this.state.lic?.licensor_id,
        "show_id": this.props?.showId,
        "letter_type": "W9W8BENREQUEST"
        }
        clearTrackService.postDataParams(
            Constants.ClearTrackServiceBaseUrl + `/sendEmail`,
            postObj,
            this.props?.userContext?.active_tenant?.tenant_id,this.props?.division_id
          ).then(
            (response) => {
              if (response.data.error) {
                this.setState({ isSubmit: false });
                this.props?.notificationComponent(true, "fail");
              } else {
               
                this.props?.notificationComponent(true, "success");
                this.setState({
                  isSubmit: false,
                });
              }
            },
            (err) => {
              this.setState({ isSubmit: false });
              this.props?.notificationComponent(true, "fail");;
            }
          );

    }

    episodeCount = () => {
        let list = [...this.state.renderList]
        list[this.state.epsCountFlag].eps_cnt = !this.state.epsCount ? this.state.eps_cnt_list?.length > 0 ? this.state.eps_cnt_list?.length : null : this.state.epsCount;
        list[this.state.epsCountFlag].episodes_list = this.state.eps_cnt_list?.length > 0 ? this.state.eps_cnt_list?.map(item => ({ ...item, ...{ episode_title: item.text, show_season_episode_id: item?.value } })) : [];
        list[this.state.epsCountFlag].is_cr_eps_updated = 1;
        this.setState({ renderList: list, setEpsCountModal: null, eps_cnt_list: [] }, () => {
            is_check_request_updated = 1
        })
    }

    statusChangeOfLicensors = () => {
        this.setState({statusChangeAlert: false, changeStatusOfAllLicensors: true});
        // , () => {
        //     this.onLicensorDropdownOptionClick("status_id", updated_status_id);
        // }
    }
    getClearanceLicensorsDate = (obj) => {

        const today = new Date();
      let currentDate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
       let field = obj==="CONFIRM" ? "confirmation_date" : obj === "QUOTE REQUEST" ? "quote_request_date" : obj === "LICENSE_REQUEST" ? "license_request_date": obj==="USE CONFIRM" ? "use_confirmation_date" :"";
      
            this.setState(prevState => ({
                lic: {
                    ...prevState.lic,
                    [field]: currentDate
                }  
            }));
          
    }

    render() {
        const { isExpanded } = this.state;
        let canEditLicensor = this.props?.isMainTitle ? this.props.mainTitlePermissions?.filter((per) => this.state.clearanceRecordPermissionsConfig.find((item) => item.permissions.includes(per.permission_name) && per.edit === 1 )) : this.props.clearancePermissions?.filter((per) => this.state.clearanceRecordPermissionsConfig.find((item) => item.permissions.includes(per.permission_name) && per.edit === 1 ))
        canEditLicensor = canEditLicensor?.length > 0 ? true : false;
        let viewLicensorPermission = this.props?.isMainTitle ? this.props.mainTitlePermissions?.find((per) => this.state.clearanceRecordPermissionsConfig.find((item) => item.buttonName == "Licensor" && item.permissions.includes(per.permission_name) && item.permissionGroupName.includes(per.permission_group_name))) : this.props.clearancePermissions?.find((per) => this.state.clearanceRecordPermissionsConfig.find((item) => item.buttonName == "Licensor" && item.permissions.includes(per.permission_name) && item.permissionGroupName.includes(per.permission_group_name)))
        let initialRightsPermission = this.props?.isMainTitle ?  this.props.mainTitlePermissions?.find((per) => this.state.clearanceRecordPermissionsConfig.find((item) => item.buttonName == "Initial" && item.permissions.includes(per.permission_name))): this.props.clearancePermissions?.find((per) => this.state.clearanceRecordPermissionsConfig.find((item) => item.buttonName == "Initial" && item.permissions.includes(per.permission_name)))
        let licensorTrackingPermission = this.props?.isMainTitle ?  this.props.mainTitlePermissions?.find((per) => this.state.clearanceRecordPermissionsConfig.find((item) => item.buttonName == "Licensor Tracking" && item.permissions.includes(per.permission_name))): this.props.clearancePermissions?.find((per) => this.state.clearanceRecordPermissionsConfig.find((item) => item.buttonName == "Licensor Tracking" && item.permissions.includes(per.permission_name)))
        let initialRightsCheckRequestsPermission = this.props?.isMainTitle ?  this.props.mainTitlePermissions?.find((per) => this.state.clearanceRecordPermissionsConfig.find((item) => item.buttonName == "initialRightsCheckRequests" && item.permissions.includes(per.permission_name))) : this.props.clearancePermissions?.find((per) => this.state.clearanceRecordPermissionsConfig.find((item) => item.buttonName == "initialRightsCheckRequests" && item.permissions.includes(per.permission_name)))
        initialRightsCheckRequestsPermission?.edit == 1 ? this.state.config?.inlineEdits?.map((item) => item.fieldDisabled = false) : this.state.config;
        let licenseStatusPermission = this.props?.isMainTitle ?  this.props.mainTitlePermissions?.find((per) => this.state.clearanceRecordPermissionsConfig.find((item) => item.buttonName == "licenseStatuses" && item.permissions.includes(per.permission_name))) : this.props.clearancePermissions?.find((per) => this.state.clearanceRecordPermissionsConfig.find((item) => item.buttonName == "licenseStatuses" && item.permissions.includes(per.permission_name)));
        let optionsConfig = viewLicensorPermission?.delete === 0 ? this.state.optionsConfig.filter(item => item.label != "Remove Licensor from this Cl R") : this.state.optionsConfig
        let open = Boolean(this.state.setPopover);
        let id = open ? 'simple-popover' : undefined;
        let openModal = Boolean(this.state.setModal);
        let openEPSModal = Boolean(this.state.setEPSModal);
        let openFlagPopup = Boolean(this.state.setFlagPopover);
        let openInvModal = Boolean(this.state.setInvModal);
        let openNotesModal = Boolean(this.state.setNotesModal);
        let idModal = open ? 'modal-popover' : undefined;
        let openEpsCountModal = Boolean(this.state.setEpsCountModal);
        let epsModal = open ? 'eps-modal-popover' : undefined;
        let messageModalTitle = this.state.showValidationAlert ? this.state.sharePercentageError :
            this.state.showVoidCheckWarning ? this.state.voidCheckError :
                this.state.showSharePercentExceededPopUp ? this.state.sharePercentExceededErrorText : this.state.statusChangeAlert ? "Apply same status to all the licensors ?" : null;
        let chkModalMessage = this.state?.showChkReqAutoAdjust ? `Check requests for licensor ${this.state?.renderList?.[0]?.payee_remit_to_name} do not add up to the licensor fee of $${this.state?.lic?.rights?.[0]?.fee}.  Would you like to auto-adjust the first check request?` : null;
        let severity = this.state.showFail ? "error" : this.state.showSuccess ? "success" : "";
        let messageNotification = this.state.showSuccess ? "The changes are saved successfully!" : this.state.showFail ? "Saving changes failed!" : "";
        let renderList = this.state.renderList?.filter(item => item.is_delete != 1)
        let commonPermissions = this.props?.userContext?.active_tenant?.common_permissions
        return (
            <MDBContainer fluid className="SyncLicensor-Container p-0" id={this?.props?.isMainTitle+this.props?.showSeasonId+(this.props.isMainTitle ? this.state.lic.clearance_licensor_id : this.state.lic.main_title_licensor_id)}>
                <NotificationComponent open={this.state.showSuccess || this.state.showFail} message={messageNotification} severity={severity}
                    handleResetNotify={this.handleResetNotify.bind(this)} />
                {(this.state.showValidationAlert || this.state.showVoidCheckWarning || this.state.statusChangeAlert) &&
                    <MessageModal
                        open={(this.state.showValidationAlert || this.state.showVoidCheckWarning || this.state.statusChangeAlert) || false}
                        title={this.state.statusChangeAlert ? "License status change" : "Saving changes failed!"}
                        message={messageModalTitle}
                        hideCancel={this.state.statusChangeAlert ? false : true}
                        primaryButtonText={"OK"}
                        secondaryButtonText={this.state.statusChangeAlert ? "CANCEL" : null}
                        onConfirm={() => this.state.statusChangeAlert ? this.statusChangeOfLicensors() : this.setState({
                            showValidationAlert: false,
                            showVoidCheckWarning: false
                        })}
                        handleClose={(e) => this.setState({
                            showValidationAlert: false,
                            showVoidCheckWarning: false,
                            statusChangeAlert: false
                        })}
                    />
                }
                {
                    <MessageModal
                        open={this.state.showSharePercentExceededPopUp || false}
                        title={"Saving changes failed!"}
                        message={messageModalTitle}
                        hideCancel={false}
                        primaryButtonText={"OK"}
                        secondaryButtonText={"CANCEL"}
                        onConfirm={() => {
                            this.setState({
                                showSharePercentExceededPopUp: false
                            });
                            this.setChkReqOnMainShareFeeChanged();
                        }}
                        handleClose={(e) => this.handleShareAutoAdjust()}
                    />
                }
                {
                    <MessageModal
                        open={this.state.showChkReqAutoAdjust || false}
                        title={"Saving changes failed!"}
                        message={chkModalMessage}
                        hideCancel={false}
                        primaryButtonText={"Yes"}
                        secondaryButtonText={"No"}
                        handleClose={() => this.setState({
                            showChkReqAutoAdjust: false
                        }, this.postLicensorData())}
                        onConfirm={(e) => this.handleAutoAdjustChkReq()}
                    />
                }
                {/* {this.state.clearneceLicenserInfo[0]?.licensors_list?.map((lic) => */}
                <div>
                    <div>
                        {/* <div> */}
                        <MDBRow style={{ marginTop: '10px' }}>
                            <MDBCol className="header-title">
                                <div>
                                    {(this.state.lic?.licensor_type === "SM" || this.state.lic?.licensor_type === 3) ?
                                        "Sync Master Licensor" : (this.state.lic?.licensor_type === "S" || this.state.lic?.licensor_type === 1) ?
                                            "Sync Licensor" : (this.state.lic?.licensor_type === "M" || this.state.lic?.licensor_type === 2) ? "Master Licensor" : "New Licensor"}
                                </div>
                                <div>
                                    <MDBRow>
                                        {this.props?.lic?.editing &&
                                            <MDBCol className="btn-cr">
                                                <BasicButton
                                                    variant="outlined"
                                                    type="inline"
                                                    color="primary"
                                                    text="cancel"
                                                    onClick={this.props?.setMode}
                                                />
                                            </MDBCol>}
                                        {canEditLicensor &&
                                            <MDBCol className="btn-cr">
                                                <BasicButton
                                                    variant="contained"
                                                    type="inline"
                                                    color="primary"
                                                    text={(this.props.lic) ?
                                                        (!this.props?.lic?.editing ?
                                                            "Edit" :
                                                            (!this.props?.isMainTitle ? this.state.postingLicensor : this.state.postingMainTitleLicensor) ?
                                                                <CircularProgress color="inherit" size={20} /> :
                                                                "Save"
                                                        ) : null
                                                    }
                                                    onClick={!this.props?.lic?.editing ? this.props?.setMode : this.postLicensorData}
                                                />
                                            </MDBCol>
                                        }
                                    </MDBRow>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </div>
                    <MDBCard className="MasterLicensor-Container">
                        <MDBCardBody>
                            <MDBCardText>
                                <div>
                                    <div>
                                        <MDBRow>
                                            <MDBCol md={1} className="icon-licensor">
                                            { viewLicensorPermission?.edit ? <>
                                                <BasicButton
                                                    className="icon-Details"
                                                    text={"Licensor"}
                                                    icon={"chevron-down"}
                                                    variant="contained"
                                                    type="inline"
                                                    color="primary"
                                                    iconRight={true}
                                                    onClick={this.handleClick}
                                                    disabled={this.state.lic?.editing || false}
                                                />
                                                <Popover
                                                    id={id}
                                                    className="licensor-popup"
                                                    open={open}
                                                    anchorEl={this.state.setPopover}
                                                    getContentAnchorEl={null}
                                                    onClose={this.handleClose}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                    PaperProps={{
                                                        style: {
                                                            width: '20%',
                                                            backgroundColor: '#F2F2F2',
                                                            borderRadius: '1px',
                                                            fontSize: '0.8rem',
                                                            fontWeight: '300',
                                                        }
                                                    }}
                                                >
                                                    {optionsConfig?.map((item, index) => {
                                                        return <div className="popover-text" onClick={this.onLicensorDropdownOptionClick.bind(this, item.label, item?.type)}>{item.label}</div>
                                                    })}

                                                </Popover>
                                            </>
                                            : null }
                                            </MDBCol>
                                            { viewLicensorPermission?.view &&
                                                <MDBCol md={3} className='text-css licensor-select'>
                                                    {(this.state.lic && !this.state.lic?.editing) ?
                                                        <div className="licensorLabel">{this.state.lic?.licensor_name}</div> :
                                                        <SearchSelectField
                                                            id="licensors-select"
                                                            width={"100%"}
                                                            options={this.state.licensorData}
                                                            isMandatory={true}
                                                            showMandatory={this.state?.postLicensorValidate}
                                                            multiple={false}
                                                            label="Licensor name"
                                                            detail_selected={this.state.lic?.clearance_licensor_name || null}
                                                            searchText={ev => {
                                                                if (ev.target.value !== "" && ev.target.value !== null) {
                                                                    this.setState({ isLicensorFetching: true }, () => {
                                                                        this.getLicensorData(ev.target.value)
                                                                    })                                                                   
                                                                } else {
                                                                    this.setState({ isLicensorFetching: false })
                                                                }
                                                            }}
                                                            loading={this.state.isLicensorFetching}
                                                            valueSelected={(e, value) => this.onChangeCallback('licensor_id', value)}
                                                            disabled={viewLicensorPermission?.edit == 1 ? false : true}
                                                            searchSelect={true}
                                                            showAsLabel={(this.state.lic) ? !this.state.lic?.editing : null}
                                                        />
                                                    }
                                                </MDBCol>
                                            }
                                            {(this.props?.isMainTitle ? viewLicensorPermission?.view === 1 : this.state.statusCROptionsPermissions?.view == 1) ?
                                                <>
                                                    <MDBCol md={1} className='MLPT5'>
                                                        <div className="sub-header">
                                                            <BasicTextField id="address_line1"
                                                                label="Share"
                                                                value={(this.state.lic) ? this.state.lic?.share == 0 ? '0' : this.state.lic?.share  : ""}
                                                                isMandatory={true}
                                                                // showMandatory={this.state?.postLicensorValidate && (!this.state.lic?.share || this.state?.lic?.share == 0)}
                                                                onChange={(e) => validateNumDotWithNegativeDecimals(e.target.value) && this.onChangeCallback('share', e.target.value?.length > 0 ? e.target.value : null)}
                                                                showAsLabel={(this.state.lic) ? !this.state.lic?.editing : null}
                                                                disabled={this.props?.isMainTitle ? (viewLicensorPermission?.edit === 1 ? false : true) : (this.state.statusCROptionsPermissions?.edit == 1 ? false : true) }
                                                            />
                                                             <span className='errorText'>{(this.state?.postLicensorValidate && (!this.state?.lic?.share )) ? 'Please complete this mandatory field': ''}</span>
                                                        </div>
                                                    </MDBCol>
                                                    <MDBCol md={1} className='MLPT5 status p-0'>
                                                        <div className="sub-header">
                                                            <SelectField
                                                                id={"type"}
                                                                label="Status"
                                                                placeHolderText='- Select -'
                                                                options={this.state.status}
                                                                isMandatory={true}
                                                                showMandatory={this.state?.postLicensorValidate}
                                                                value={this.state.lic?.status_id || ""}
                                                                onChange={(e, value) => this.onChangeCallback('status_id', e.target.value)}
                                                                showAsLabel={(this.state.lic) ? !this.state.lic?.editing : null}
                                                                disabled={this.props?.isMainTitle ? (viewLicensorPermission?.edit === 1 ? false : true) : this.state.statusCROptionsPermissions?.edit == 1 ? false : true }
                                                            />
                                                        </div>
                                                    </MDBCol>
                                                    <MDBCol md={3} className={"mfn-icon-parent"}>
                                                        {this.state?.lic?.licensor_type === "S" && (
                                                            <>
                                                                <div className='MLPT5 mfn-child'>
                                                                    {this.state.lic?.editing ?
                                                                        <BasicCheckbox
                                                                            id="mfnWMaster"
                                                                            label={"MFN w/ Master"}
                                                                            disabled={this.props?.isMainTitle ? (viewLicensorPermission?.edit === 1 ? false : true) : this.state.statusCROptionsPermissions?.edit == 1 ? false : true }
                                                                            checked={!!this.state?.lic?.is_mfn_with_M || null}
                                                                            onChange={(e) => this.onChangeCallback('is_mfn_with_M', +e.target.checked)}
                                                                        /> :
                                                                        <>
                                                                            <div className='sub-header'>
                                                                                <BasicLabel text={"MFN w/ Master"} />
                                                                                <div> {!!this.state?.lic?.is_mfn_with_M == true ? "Yes" : "No"}</div>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                </div>
                                                                {(this.state?.lic?.calc_co_pub_fee_based_on_current_sync == 1 ||  this.props.totalSyncLicensor > 1) && <div className='MLPT5 mfn-child'>
                                                                    {this.state.lic?.editing ?
                                                                        <BasicCheckbox
                                                                            id="mfnWCoPub"
                                                                            label={"MFN w/ Co-pub(s)"}
                                                                            disabled={this.props?.isMainTitle ? (viewLicensorPermission?.edit === 1 ? false : true) : this.state.statusCROptionsPermissions?.edit == 1 ? false : true }
                                                                            checked={!!this.state?.lic?.is_mfn_with_Co_Pub || null}
                                                                            onChange={(e) => this.onChangeCallback('is_mfn_with_Co_Pub', +e.target.checked)}
                                                                        /> :
                                                                        <>
                                                                            <div className='sub-header'>

                                                                                <BasicLabel text={"MFN w/ Co-pub(s)"} />
                                                                                <div className='showAsLabel'> {!!this.state?.lic?.is_mfn_with_Co_Pub == true ? "Yes" : "No"}</div>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                </div>
                                                                }
                                                            </>)
                                                        }
                                                        {this.state?.lic?.licensor_type === "M" && 
                                                            <>
                                                               {(this.state?.lic?.calc_co_master_fee_based_on_current_master == 1 || this.state?.lic?.calc_master_co_master_based_on_current_sync == 1 || this.state?.lic?.calc_sync_co_pub_fee_based_on_master == 1 || this.props.totalMasterLicensor > 1) && <div className='MLPT5 mfn-child'>
                                                                    {this.state.lic?.editing ?
                                                                        <BasicCheckbox
                                                                            id="mfnWMaster"
                                                                            label={"MFN w/ Co-master(s)"}
                                                                            // disabled={!this.props?.isEditing || false}
                                                                            checked={!!this.state?.lic?.is_mfn_with_Co_M || null}
                                                                            onChange={(e) => this.onChangeCallback('is_mfn_with_Co_M', +e.target.checked)}
                                                                        /> :
                                                                        <>
                                                                            <div className='sub-header'>
                                                                                <BasicLabel text={"MFN w/ Co-master(s)"} />

                                                                                <div className='showAsLabel'> {!!this.state?.lic?.is_mfn_with_Co_M == true ? "Yes" : "No"}</div>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                </div>
                                                                }
                                                                <div className='MLPT5 mfn-child'>
                                                                    {this.state.lic?.editing ?
                                                                        <BasicCheckbox
                                                                            id="mfnWCoPub"
                                                                            label={"MFN w/ Pub(s)"}
                                                                            // disabled={!this.props?.isEditing || false}
                                                                            checked={!!this.state?.lic?.is_mfn_with_Pub || null}
                                                                            onChange={(e) => this.onChangeCallback('is_mfn_with_Pub', +e.target.checked)}
                                                                        /> :
                                                                        <>
                                                                            <div className='sub-header'>

                                                                                <BasicLabel text={"MFN w/ Pub(s)"} />
                                                                                <div className='showAsLabel'> {!!this.state?.lic?.is_mfn_with_Pub == true ? "Yes" : "No"}</div>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                </div>
                                                            </>
                                                        }
                                                        <div className="p-0 expand-icon1 licensor-mfn-icon1">
                                                            {viewLicensorPermission?.view === 1 &&
                                                            <span>
                                                                <BasicButton icon="arrows-alt-v"
                                                                    text=" "
                                                                    variant="contained"
                                                                    className="arrow_alt_icon"
                                                                    onClick={this.handleClickModal}
                                                                    disabled={this.state.lic?.editing ? (this.props?.isMainTitle ? (viewLicensorPermission?.edit === 1 ? false : true) : this.state.statusCROptionsPermissions?.edit == 1 ? false : true) : null }
                                                                />
                                                                <Popover
                                                                    id={idModal}
                                                                    open={openModal}
                                                                    className={"pop-over"}
                                                                    anchorEl={this.state.setModal}
                                                                    onClose={this.handleCloseModal}
                                                                    anchorOrigin={{
                                                                        vertical: 'bottom',
                                                                        horizontal: 'left',
                                                                    }}
                                                                    transformOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'left',
                                                                    }}
                                                                    PaperProps={{ style: { width: '24%', backgroundColor: '#F2F2F2', fontSize: '0.65rem', fontWeight: 400, borderRadius: '1px' } }}
                                                                >
                                                                    <MasterLicensorModal
                                                                        onChangeCallback={this.onStateLicChange}
                                                                        lic={this.state.lic}
                                                                        isEditing={this.state.lic?.editing || false}
                                                                        onClose={this.handleCloseModal}
                                                                        isMainTitle={this.props.isMainTitle}
                                                                        songContainsSample={!!this.props?.selectedSong?.is_contains_simple || false}

                                                                    />
                                                                </Popover>
                                                            </span>}
                                                        </div>
                                                    </MDBCol>
                                                    <MDBCol md={1}>
                                                           {this.state.lic?.is_provides_own_license === 1 ? <span className='yellowBG'>They Provide License</span> : <span className='blueBG'>We Provide License</span>}         
                                                    </MDBCol>
                                                </> : 
                                                <MDBCol md={6} />
                                            }
                                            <MDBCol md={2} className="p-0 licensor-tracking">
                                                <div className='FR'>
                                                    <div className="sub-header">
                                                        <div className="header-text">Licensee Rep</div>
                                                        {(this.state.lic && !this.state.lic?.editing && viewLicensorPermission?.view === 1) ?
                                                            <div className="licensorRepLabel">
                                                                {/* {this.state.licensorRepOptions?.find(rep => rep.value === this.state.lic?.licensee_rep_id)?.label || '-'} */}
                                                                {this.state.lic?.licensee_rep_name || '-'}
                                                            </div> :
                                                            <div className="licensee-p">
                                                                <SelectField
                                                                    id="rep"
                                                                    placeholder="- Search -"
                                                                    loading={this.state.isLicenseeRepFetching}
                                                                    value={this.state.lic?.licensee_rep_id || ''}
                                                                    options={this.state.licensorRepOptions || []}
                                                                    // isMandatory={true}
                                                                    // showMandatory={this.state?.postLicensorValidate}
                                                                    onChange={(e) => this.onChangeCallback('selected_licensee_rep', e?.target?.value)}
                                                                    disabled={this.props?.isMainTitle ? (viewLicensorPermission?.edit === 1 ? false : true) : this.state.statusCROptionsPermissions?.edit == 1 ? false : true }
                                                                    // searchText={ev => {
                                                                    //     // dont fire API if the user delete or not entered anything
                                                                    //     if (ev.target.value !== "" && ev.target.value !== null) {
                                                                    //         this.getLicensorRepData("USER", "licensorRepOptions", ev.target.value);
                                                                    //     } else {
                                                                    //         this.setState({ isLicenseeRepFetching: false })
                                                                    //     }
                                                                    // }}
                                                                    showAsLabel={(this.state.lic) ? !this.state.lic?.editing : null}

                                                                />
                                                                 <br/>
                                                                 <br/>
                                                            </div>
                                                        }
                                                    </div>
                                                   
                                                    <div className="licensor-row">
                                                        {(this.state.lettersPermission?.view || this.state.lQCPermissions?.view) ? <BasicButton
                                                            className="email-btn"
                                                            variant="outlined"
                                                            text=" "
                                                            icon={"envelope"}
                                                            onClick={!this.props?.isMainTitle ? this.handleshowEditLettersModal : this.handleShowMainTitleLettersModal}
                                                        /> : null }
                                                        {licensorTrackingPermission?.view &&
                                                            <BasicButton
                                                                variant="contained"
                                                                type="inline"
                                                                color="primary"
                                                                text={"License Tracking"}
                                                                onClick={this.state.showLicenseTackingModal ? this.handleshowLicenseTackingModal : this.handleShowMainLicenseTackingModal}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow className={this.state.lic?.editing ? 'second-row' : ''}>
                                            <MDBCol>
                                                <MDBRow md={10} >
                                                    <MDBCol md={1} className='PT4'>
                                                        {viewLicensorPermission?.view === 1 &&  <>
                                                        <div className="licensor-header-text">S/M <span className='required errorText'>*</span></div>
                                                        <SelectField
                                                            id={"type"}
                                                            placeHolderText='- Select -'
                                                            isMandatory={true}
                                                            showMandatory={this.state?.postLicensorValidate}
                                                            options={this.props?.selectLicensorType}
                                                            value={(this.state.lic) ? this.state.lic?.licensor_type === "S" ? 1 :
                                                                this.state.lic?.licensor_type === "M" ? 2 :
                                                                    this.state.lic?.licensor_type === "SM" ? 3 :
                                                                        this.state.lic?.licensor_type : ""}
                                                            onChange={(e) => this.onChangeCallback('licensor_type', e.target.value)}
                                                            showAsLabel={(this.state.lic) ? !this.state.lic?.editing : null}
                                                            disabled={this.props?.isMainTitle ? (viewLicensorPermission?.edit === 1 ? false : true) : this.state.statusCROptionsPermissions?.edit == 1 ? false : true }
                                                        />
                                                        </>}
                                                    </MDBCol>
                                                    <MDBCol
                                                        md={this.state.lic?.editing ? 7 : 5}
                                                        className={`${!this.props?.isMainTitle ? "clearance-rights-section" : ""} ${!this.state.lic?.editing ? 'mt-4' : ""} p-0`}
                                                    >
                                                        {/* {viewIntialRightsInfoPermission && */}
                                                            <>
                                                                {this.state.lic?.rights?.map((item, index) =>
                                                                    <>
                                                                        {/* {editIntialRightsInfoPermission && this.state.lic?.editing ? */}
                                                                        {this.state.lic?.editing ?
                                                                            <MDBRow className="g-0 mt-2">
                                                                                <MDBCol md={1} className={`${index > 0 ? 'nextIndexInitialLabel' : 'initialLabel'}`}>
                                                                                    {(item.is_initial && (initialRightsPermission?.view || initialRightsPermission?.edit)) ? "Initial" : (item.is_alt_initial && (initialRightsPermission?.view || initialRightsPermission?.edit)) ? "Alt Initial" : null}
                                                                                </MDBCol>
                                                                                <MDBCol md={4} id="AiMedia">
                                                                                    {index === 0 && (initialRightsPermission?.view || initialRightsPermission?.edit) ?
                                                                                        <div className="header-text">Rights * </div> : null
                                                                                    }
                                                                                    { initialRightsPermission?.view ?
                                                                                    <SelectField
                                                                                        id={"type" + index}
                                                                                        placeHolderText='- Select -'
                                                                                        options={item.is_initial ? this.state.rights : this.state.altInitRights}
                                                                                        value={item.right_id}
                                                                                        isMandatory={item.is_alt_initial == 1 ? false : true}
                                                                                        showMandatory={this.state?.postLicensorValidate}
                                                                                        disabled={initialRightsPermission?.edit == 1 ? false : true}
                                                                                        onChange={(e) => {
                                                                                            let selectedRight = this.state.allrightsofsystem?.find(item => item?.right_id === e.target.value)
                                                                                            this.onChangeCallback('right_id', e.target.value, index)
                                                                                            this.onChangeCallback('term_id', selectedRight?.default_term_id ? selectedRight?.default_term_id : null, index)
                                                                                            this.onChangeCallback('territory_id', selectedRight?.default_territory_id ? selectedRight?.default_territory_id : null, index)
                                                                                        }}
                                                                                    /> : null}
                                                                                </MDBCol>
                                                                                <MDBCol md={2} id="Prep">
                                                                                    {index === 0 && (initialRightsPermission?.view || initialRightsPermission?.edit) ?
                                                                                        <div className="header-text">Term *</div> : null
                                                                                    }
                                                                                    { initialRightsPermission?.view && 
                                                                                    <SelectField
                                                                                        id={"termSelect" + index}
                                                                                        placeHolderText='- Select -'
                                                                                        options={this.state.term}
                                                                                        value={item.term_id}
                                                                                        disabled={initialRightsPermission?.edit == 1 ? false : true}
                                                                                        onChange={(e) => this.onChangeCallback('term_id', e.target.value, index)}
                                                                                    />}
                                                                                </MDBCol>
                                                                                <MDBCol md={2} id="Territory">
                                                                                    {index === 0 && (initialRightsPermission?.view || initialRightsPermission?.edit) ?
                                                                                        <div className="header-text">Territory *</div> : null
                                                                                    }
                                                                                    { initialRightsPermission?.view &&
                                                                                    <SelectField
                                                                                        id={"terr" + index}
                                                                                        placeHolderText='- Select -'
                                                                                        options={this.state.territory}
                                                                                        value={item.territory_id}
                                                                                        isMandatory={item.is_alt_initial == 1 ? false : true}
                                                                                        showMandatory={this.state?.postLicensorValidate}
                                                                                        disabled={initialRightsPermission?.edit == 1 ? false : true}
                                                                                        onChange={(e) => this.onChangeCallback('territory_id', e.target.value, index)}
                                                                                    />}
                                                                                </MDBCol>
                                                                                <MDBCol md={2} id="Fee">
                                                                                    {index === 0 && (initialRightsPermission?.view || initialRightsPermission?.edit) ?
                                                                                        <div className="header-text">Fee </div> : null
                                                                                    }
                                                                                    {initialRightsPermission?.view  &&
                                                                                    <div id='FeeText'>
                                                                                        <BasicTextField
                                                                                            id={"fee" + index}
                                                                                            value={item.fee}
                                                                                            onChange={(e) => validateNumDotWith3Decimals(e.target?.value) && this.onChangeCallback('fee', e.target.value, index)}
                                                                                            InputProps={{
                                                                                                startAdornment: (
                                                                                                    <InputAdornment position="start">$</InputAdornment>
                                                                                                ),
                                                                                            }}
                                                                                            disabled={initialRightsPermission?.edit == 1 ? false : true}
                                                                                        />
                                                                                    </div>}
                                                                                </MDBCol>
                                                                            </MDBRow> :
                                                                            <div className="d-flex rightsViewMargin">
                                                                                <div className='rightViewSectionText p-0'>{item.is_initial ?(initialRightsPermission?.view  ? ('(Init) ') : null) : (initialRightsPermission?.view ? ('(Alt Init) ') : null )}</div>
                                                                                <div className='rightViewSectionText p-0'>
                                                                                    {item?.right_id ?
                                                                                        <>
                                                                                            {item.is_initial ?
                                                                                                (initialRightsPermission?.view  ? this.state.rights?.find(r => r.value === item?.right_id)?.label : null):
                                                                                                this.state.altInitRights?.find(r => r.value === item?.right_id)?.label
                                                                                            }
                                                                                        </> : '-'
                                                                                    }
                                                                                </div>
                                                                                {item?.term_id && initialRightsPermission?.view  ?
                                                                                    <div className='rightViewSectionText p-0'>
                                                                                        {'/ ' + this.state.term?.find(r => r.value === item?.term_id)?.label}
                                                                                    </div> : null
                                                                                }
                                                                                {item?.territory_id && initialRightsPermission?.view  ?
                                                                                    <div className='rightViewSectionText p-0'>
                                                                                        {'/ ' + this.state.territory?.find(r => r.value === item?.territory_id)?.label}
                                                                                    </div> : null
                                                                                }
                                                                                {/* {item?.fee && initialRightsPermission?.view  ?
                                                                                    <div className='rightViewSectionText p-0'>
                                                                                        {'- ' + '$' + item?.fee}
                                                                                    </div> : null
                                                                                } */}
                                                                                <div className='rightViewSectionText p-0'>
                                                                                    {item.fee !== null ? `- $${item.fee}` : " "}
                                                                                    </div>

                                                                            </div>
                                                                        }
                                                                    </>
                                                                )}
                                                            </>
                                                        {/* } */}
                                                    </MDBCol>
                                                    {initialRightsPermission?.view === 1 &&
                                                        <>
                                                            <MDBCol md={3} className={`quote-req-section p-0 ${!this.state.lic?.editing ? 'quote-req-section-view' : ''}`}>
                                                                <MDBRow>
                                                                    <MDBCol className='d-flex'>
                                                                        <DateField
                                                                        id={"quote-req-dt"}
                                                                        label="Quote Req. Dt"
                                                                        value={this.state.lic?.quote_request_date || "-"}
                                                                        onChange={(e) => initialRightsPermission?.edit === 1 ?
                                                                            this.onChangeCallback(
                                                                                "quote_request_date",
                                                                                e.target.value?.length > 0 ? e.target.value : null
                                                                            ) : null
                                                                        }
                                                                        // showAsLabel={(this.state.lic) && viewIntialRightsInfoPermission ? !this.state.lic?.editing : null}
                                                                        showAsLabel={(this.state.lic) ? !this.state.lic?.editing : null}
                                                                    />
                                                                    <DateField
                                                                        id={"confirm_ltr"}
                                                                        label="Confirm. Ltr"
                                                                        value={this.state.lic?.confirmation_date || "-"}
                                                                        onChange={(e) => initialRightsPermission?.edit === 1 ?
                                                                            this.onChangeCallback(
                                                                                "confirmation_date",
                                                                                e.target.value?.length > 0 ? e.target.value : null
                                                                            ) : null
                                                                        }
                                                                        // showAsLabel={(this.state.lic) && viewIntialRightsInfoPermission ? !this.state.lic?.editing : null}
                                                                        showAsLabel={(this.state.lic)  ? !this.state.lic?.editing : null}
                                                                    />
                                                                    <DateField
                                                                        id={"lic-req-date"}
                                                                        label="Lic. Req. Dt"
                                                                        value={this.state.lic?.license_request_date || "-"}
                                                                        onChange={(e) => initialRightsPermission?.edit === 1 ?
                                                                            this.onChangeCallback(
                                                                                "license_request_date",
                                                                                e.target.value?.length > 0 ? e.target.value : null
                                                                            ) : null
                                                                        }
                                                                        // showAsLabel={(this.state.lic) && viewIntialRightsInfoPermission ? !this.state.lic?.editing : null}
                                                                        showAsLabel={(this.state.lic)  ? !this.state.lic?.editing : null}
                                                                    />
                                                                    {this.state.lic?.is_useConfirmation == 1 && !this.state.lic?.editing && 
                                                                     <DateField
                                                                      id={"useConfirm_ltr"}
                                                                      label="Use Confirm. Ltr"
                                                                      value={this.state.lic?.use_confirmation_date || "-"}
                                                                      onChange={(e) => initialRightsPermission?.edit === 1 ?
                                                                          this.onChangeCallback(
                                                                              "use_confirmation_date",
                                                                              e.target.value?.length > 0 ? e.target.value : null
                                                                          ) : null
                                                                      }
                                                                      showAsLabel={(this.state.lic)  ? !this.state.lic?.editing : null}
                                                                      />}
                                                                   </MDBCol>
                                                                </MDBRow>
                                                                {this.state.lic?.is_useConfirmation == 1 && this.state.lic?.editing &&
                                                                <MDBRow>
                                                                <MDBCol>
                                                                  <DateField
                                                                      id={"useConfirm_ltr_edit"}
                                                                      label="Use Confirm. Ltr"
                                                                      value={this.state.lic?.use_confirmation_date || "-"}
                                                                      onChange={(e) => initialRightsPermission?.edit === 1 ?
                                                                          this.onChangeCallback(
                                                                              "use_confirmation_date",
                                                                              e.target.value?.length > 0 ? e.target.value : null
                                                                          ) : null
                                                                      }
                                                                    //   showAsLabel={(this.state.lic)  ? !this.state.lic?.editing : null}
                                                                      />
                                                                  </MDBCol>
                                                              </MDBRow>
                                                                }
                                                              </MDBCol> 
                                                        </>
                                                    }
                                                </MDBRow>
                                            </MDBCol>
                                            {licenseStatusPermission?.view == 1 && 
                                            <MDBCol md={2} className="btn-circle-2 mt-2">
                                                {this.state.licenseTrackingData?.license_tracking?.map(item => (
                                                    <div className="quote-request-list">
                                                        <MDBIcon
                                                            className={this.state.licenseTrackingData?.is_closed === 1 ? "check-icon" : "cancle-icon"}
                                                            icon={this.state.licenseTrackingData?.is_closed  === 1 ? "check-circle" : "times-circle"}
                                                        />
                                                        <div className="quote-request-label icon-space">{item?.action_name}</div>
                                                        <div>{getFormattedDate(item.date)}</div>
                                                    </div>
                                                ))}
                                            </MDBCol>}
                                        </MDBRow>
                                        {initialRightsCheckRequestsPermission?.view == 1 &&
                                            <>
                                                <MDBRow className="check-request-accordian">

                                                    <MDBCol md={12}>
                                                        <div className="cueSheet files-container" class='row' id='mainGrid'>
                                                            {this.state.inValidChkReq &&
                                                                <MDBCol md={12}>
                                                                    <span className='errorText'>Payee, Lic%, Share, Init.fee Mandatory For All The Records Inside Check Request.</span>
                                                                </MDBCol>
                                                            }
                                                            <Accordion expanded = {isExpanded} className="icon-Details">
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMoreIcon />}
                                                                    onClick = {this.handleChange}
                                                                    aria-controls="panel1bh-content"
                                                                    id="panel1bh-header"
                                                                >
                                                                    <Typography className="header-cue" >
                                                                        Check Requests
                                                                    </Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails id="optional-rights-table-bg" className="optionalRightsTable">
                                                                    {this.state.config?.headings[1]?.selectOptions?.length > 0 && (
                                                                        <div className="syncLicensorTable" id="table-bg-sync">
                                                                            <p className='errorMessage'>{this.state.MandatoryCheckRequestError && `Please enter payee, lic%, share % and init fee field's value`}</p>
                                                                            <TableComponent
                                                                                allPayeeRemitTo={this.state?.allPayeeRemitTo}
                                                                                getPayeeData={this.getPayeeData.bind(this)}
                                                                                idForFocus={'licensorCrgrid'}
                                                                                list={renderList || []}
                                                                                className={"clearencRecord"}
                                                                                config={this.state.config}
                                                                                handlepopupCallback={this.handleSyncPopupClick.bind(this)}
                                                                                // handleOpenInlineModal={this.handleClickEPSModal}
                                                                                handleDelCallBack={initialRightsCheckRequestsPermission?.delete == 1 ? this.handleDeleteCheckRequest.bind(this) : null}
                                                                                handleOpenInlineModal={this.handleOpenLicensorInlineModals}
                                                                                handleAddNewItem={initialRightsCheckRequestsPermission?.edit == 1 ? this.handleAddNewItem.bind(this) : null}
                                                                                addItemToList={this.state.lic?.editing ? true : false}
                                                                                handleFlagPopover={this.handleShowFlagPopover}
                                                                                checkUpdatedValues={this.check.bind(this)}
                                                                                updateOptCheckRequest={this.updateOptCheckRequest.bind(this)}
                                                                                handleValuePopulation={this.handleDefaultValuePopulation.bind(this)}
                                                                                defaultValuePopulation={this.state?.defaultPayee || null}
                                                                                mode={this.state.mode}
                                                                                removeMinus={true}
                                                                                handleEditPayeeModal={this.handleEditPayeeModal.bind(this)}
                                                                                validateNewItem={(newDataItem) => {
                                                                                    if (newDataItem?.payee_remit_to?.value && newDataItem?.lic_per && newDataItem?.share && newDataItem?.init_fee && validateNumDotWithNegative(newDataItem?.lic_per) && validateNumDotWithNegative(newDataItem?.share) && validateNumDotWithNegative(newDataItem?.init_fee)) {
                                                                                        this.setState({ MandatoryCheckRequestError: false })
                                                                                        return true;
                                                                                    } else {
                                                                                        this.setState({ MandatoryCheckRequestError: true })
                                                                                        return false;
                                                                                    }
                                                                                }}
                                                                            />
                                                                            <Popover
                                                                                open={openInvModal}
                                                                                className="flag-popover"
                                                                                anchorEl={this.state.setInvModal}
                                                                                onClose={() => {
                                                                                    this.setState({
                                                                                        setInvModal: null
                                                                                    })
                                                                                }}
                                                                                anchorOrigin={{
                                                                                    vertical: 'bottom',
                                                                                    horizontal: 'left',
                                                                                }}
                                                                                transformOrigin={{
                                                                                    vertical: 'top',
                                                                                    horizontal: 'left',
                                                                                }}
                                                                                PaperProps={{ style: { backgroundColor: '#F2F2F2', fontSize: '12px', fontWeight: 350, borderRadius: '1px' } }}
                                                                            >
                                                                                <NotesModal
                                                                                    notesValue={this.state.checkRequestInv?.inv}
                                                                                    field={"inv_notes"}
                                                                                    onChangeCallback={ this.onChangeCallback.bind(this) }
                                                                                    // initialRightsCheckRequestsPermission = { this.props.clearancePermissions?.find((per) => this.state.clearanceRecordPermissionsConfig.find((item) => item.buttonName == "initialRightsCheckRequests" && item.permissions.includes(per.permission_name))) }
                                                                                    initialRightsCheckRequestsPermission={initialRightsCheckRequestsPermission}
                                                                                    isEditMode={this.props?.lic?.editing}
                                                                                    onClose={() => {
                                                                                        this.setState({
                                                                                            setInvModal: null
                                                                                        })
                                                                                    }}
                                                                                />
                                                                            </Popover>
                                                                            <Popover
                                                                                open={openEpsCountModal}
                                                                                className="flag-popover"
                                                                                style={{
                                                                                    width: '1800px',
                                                                                    // height: '200px'
                                                                                }}
                                                                                anchorEl={this.state.setEpsCountModal}
                                                                                onClose={() => {
                                                                                    this.setState({
                                                                                        setEpsCountModal: null
                                                                                    })
                                                                                }}
                                                                                anchorOrigin={{
                                                                                    vertical: 'bottom',
                                                                                    horizontal: 'left'
                                                                                }}
                                                                                transformOrigin={{
                                                                                    vertical: 'top',
                                                                                    horizontal: 'left'
                                                                                }}

                                                                            >
                                                                                <div>
                                                                                    <div style={{ fontSize: '0.65rem', fontWeight: 600 }}>Select the Episodes this check is paying for </div>
                                                                                    <div className={"eps-height"}>
                                                                                        <SearchSelectField
                                                                                            placeHolderText='-Select Episodes-'
                                                                                            disabled={this.state.lic?.editing ? false : true}
                                                                                            multiple={true}
                                                                                            value={this.state.eps_cnt_list || []}
                                                                                            options={this.state.episodeOptions || []}
                                                                                            onChange={(e, value) => this.onChangeCallback('eps_cnt_list', value)}

                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <MDBRow>
                                                                                    <MDBCol md={8}></MDBCol>
                                                                                    <MDBCol md={3}>
                                                                                        <MDBRow >
                                                                                            <MDBCol className="display-modal-2">
                                                                                                <div> <BasicButton
                                                                                                    className={"modal-btn-save"}
                                                                                                    disabled={this.state.lic?.editing ? false : true}
                                                                                                    variant="contained"
                                                                                                    text=" "
                                                                                                    icon={"save"}
                                                                                                    onClick={this.episodeCount}
                                                                                                /></div>
                                                                                                <div>
                                                                                                    <BasicButton
                                                                                                        className={"modal-btn"}
                                                                                                        variant="outlined"
                                                                                                        text=" "
                                                                                                        icon={"times"}
                                                                                                        onClick={() => {
                                                                                                            this.setState({
                                                                                                                setEpsCountModal: null
                                                                                                            })
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            </MDBCol>
                                                                                        </MDBRow>
                                                                                    </MDBCol>
                                                                                </MDBRow>

                                                                            </Popover>
                                                                            <Popover
                                                                                open={openNotesModal}
                                                                                className="flag-popover"
                                                                                anchorEl={this.state.setNotesModal}
                                                                                onClose={() => {
                                                                                    this.setState({
                                                                                        setNotesModal: null
                                                                                    })
                                                                                }}
                                                                                anchorOrigin={{
                                                                                    vertical: 'bottom',
                                                                                    horizontal: 'left',
                                                                                }}
                                                                                transformOrigin={{
                                                                                    vertical: 'top',
                                                                                    horizontal: 'left',
                                                                                }}   
                                                                                // container={this.state.setNotesModal}                                                                             
                                                                                PaperProps={{ style: { backgroundColor: '#F2F2F2', fontSize: '12px', fontWeight: 350, borderRadius: '1px' } }}
                                                                            >
                                                                                <NotesModal
                                                                                    notesValue={this.state.checkRequestNotes?.notes}
                                                                                    field={"cr_notes"}
                                                                                    onChangeCallback={this.onChangeCallback.bind(this)}
                                                                                    isEditMode={this.props?.lic?.editing}
                                                                                    onClose={() => {
                                                                                        this.setState({
                                                                                            setNotesModal: null
                                                                                        })
                                                                                    }}
                                                                                    // initialRightsCheckRequestsPermission = { this.props.clearancePermissions?.find((per) => this.state.clearanceRecordPermissionsConfig.find((item) => item.buttonName == "initialRightsCheckRequests" && item.permissions.includes(per.permission_name))) }
                                                                                    initialRightsCheckRequestsPermission={initialRightsCheckRequestsPermission}
                                                                                />
                                                                            </Popover>
                                                                            <Popover
                                                                                open={openFlagPopup}
                                                                                className="flag-popover"
                                                                                anchorEl={this.state.setFlagPopover}
                                                                                onClose={this.handleCloseFlagPopup}
                                                                                anchorOrigin={{
                                                                                    vertical: 'bottom',
                                                                                    horizontal: 'left',
                                                                                }}
                                                                                transformOrigin={{
                                                                                    vertical: 'top',
                                                                                    horizontal: 'left',
                                                                                }}
                                                                                PaperProps={{ style: { backgroundColor: '#F2F2F2', fontSize: '12px', fontWeight: 350, borderRadius: '1px' } }}
                                                                            >
                                                                                {this.state.syncLicensorFlagConfig?.map((item, index) => {
                                                                                    return <div className="popover-content-value" onClick={() => this.handleShowPayeeModal(item?.label === "Edit Payee" ? "Edit" : "New")}>{item?.label}</div>
                                                                                })}

                                                                            </Popover>
                                                                            <Popover
                                                                                open={openEPSModal}
                                                                                anchorEl={this.state.setEPSModal}
                                                                                onClose={this.handleCloseEPSModal}
                                                                                anchorOrigin={{
                                                                                    vertical: 'bottom',
                                                                                    horizontal: 'left',
                                                                                }}
                                                                                transformOrigin={{
                                                                                    vertical: 'top',
                                                                                    horizontal: 'left',
                                                                                }}
                                                                                PaperProps={{ style: { width: '20%', backgroundColor: '#F2F2F2', fontSize: '0.65rem', fontWeight: 400, borderRadius: '1px' } }}
                                                                            >

                                                                                <div >
                                                                                    <div style={{ fontSize: '0.65rem', fontWeight: 600 }}>Select the Episodes this check is paying for </div>
                                                                                    <div>
                                                                                        <SelectField
                                                                                            placeHolderText='-Select Episodes-'
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <MDBRow>
                                                                                    <MDBCol md={9}></MDBCol>
                                                                                    <MDBCol md={3}>
                                                                                        <MDBRow >
                                                                                            <MDBCol className="display-modal-2">
                                                                                                <div> <BasicButton
                                                                                                    className={"modal-btn-save"}
                                                                                                    variant="contained"
                                                                                                    text=" "
                                                                                                    icon={"save"}
                                                                                                /></div>
                                                                                                <div>
                                                                                                    <BasicButton
                                                                                                        className={"modal-btn"}
                                                                                                        variant="outlined"
                                                                                                        text=" "
                                                                                                        icon={"times"}
                                                                                                    />
                                                                                                </div>
                                                                                            </MDBCol>
                                                                                        </MDBRow>
                                                                                    </MDBCol>
                                                                                </MDBRow>
                                                                            </Popover>
                                                                        </div>
                                                                    )}
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </div>
                                                    </MDBCol>
                                                    <CheckRequestPopUp
                                                        checkRequestAnchorEl={this.state.setCheckRequestPopUp}
                                                        listName={!this.props?.isMainTitle ?
                                                            "clearance_check_request" :
                                                            "main_title_check_request"
                                                        }
                                                        userName={this.props?.userContext?.user_profile?.full_name || ''}
                                                        selectedCheckRequest={this.state.selectedCheckRequest}
                                                        selectedChkReqIndex={this.state?.selectedChkReqIndex}
                                                        onChangeCallback={this.handleCRPopUpOnChange.bind(this)}
                                                        lic={this.state.lic}
                                                        isEditing={this.state.lic?.editing || false}
                                                        open={this.state.setCheckRequestPopUp}
                                                        handleVoidCheck={this.handleVoidCheck}
                                                        handleOnClose={() => this.setState({ setCheckRequestPopUp: false, selectedCheckRequest: null })}
                                                        initialRightsCheckRequestsPermission={initialRightsCheckRequestsPermission}
                                                    />
                                                </MDBRow>
                                            </>
                                        }
                                        <MDBRow>
                                            <CueSheetPublisher
                                                divisionId={this.props?.division_id}
                                                getPayeeData={this.getPayeeData.bind(this)}
                                                allPayeeRemitTo={this.state?.allPayeeRemitTo}
                                                selectedSong={this.props?.selectedSong}
                                                show_name={this.props?.show_name}
                                                optionalRightKey={!this.props?.isMainTitle ? 'clearance_licensor_optional_rights' :
                                                    'main_title_licensor_optional_rights'
                                                }
                                                optionalRightCheckRequestKey={!this.props?.isMainTitle ? 'clearance_licensor_optional_check_requests' :
                                                    'main_title_licensor_optional_check_requests'
                                                }
                                                isMainTitle={this.props?.isMainTitle || false}
                                                isLicensorOptionalRightsUpdated={() => this.setState({ isLicensorOptionalRightsUpdated: 1 })}
                                                inActiveOptionalRights={this.state.inActiveOptionalRights}
                                                handleInActiveOptionalRights={(value) => this.setState({ inActiveOptionalRights: value })}
                                                inActiveOptionalRightCheckRequest={this.state.inActiveOptionalRightCheckRequest}
                                                handleInActiveOptionalRightCheckReq={(value, optionalRightIndex) =>
                                                    this.setState({
                                                        inActiveOptionalRightCheckRequest: value,
                                                        inActiveOptionalRightIndex: optionalRightIndex
                                                    })
                                                }
                                                inValidOptionalReq={this.state.inValidOptionalReq}
                                                inValidOptionalChkReq={this.state?.inValidOptionalChkReq}
                                                optionalRightsConfig={this.state.optionalRightsConfig}
                                                CueSheetPublisherConfig={this.state.CueSheetPublisherConfig}
                                                CueSheetPublisherList={this.props?.CueSheetPublisherList}
                                                handleListEdit={this.props?.handleListEdit}
                                                defaultPayee={this.state?.defaultPayee || null}
                                                handleGeneralEdit={this.props.handleGeneralEdit}
                                                onChangeCallback={this.onStateLicChange.bind(this)}
                                                handleAddOptionFeeToCheckRequest={this.handleAddOptionFeeToCheckRequest}
                                                handleOpenOptionalPayeeInlineModals={this.handleOpenOptionalPayeeInlineModals}
                                                setOpInvModal={this.state.setOpInvModal}
                                                setOpNotesModal={this.state.setOpNotesModal}
                                                optionalPayeeInv={this.state.optionalPayeeInv}
                                                optionalPayeeNotes={this.state.optionalPayeeNotes}
                                                handleCloseOpInvModal={() => this.setState({ setOpInvModal: null })}
                                                handleCloseOpNotesModal={() => this.setState({ setOpNotesModal: null })}
                                                handleOnChange={this.onChangeCallback}
                                                handleVoidCheck={this.handleVoidCheck}
                                                lic={this.state?.lic}
                                                mode={this.state.mode}
                                                clearneceLicenserInfo={this.props.clearneceLicenserInfo}
                                                licensorsInfo={this.props?.licensorsInfo}
                                                postLicensorData={() => !this.props?.lic?.editing ? this.postLicensorData() : ''}
                                                song_id={this.props?.song_id}
                                                // permissionArrClearanceRecords={!this.props?.isMainTitle ? this.props?.permissionArrClearanceRecords : this.props?.permissionArrMainTitile}
                                                toggleRefreshPage={this.props?.toggleRefreshPage}
                                                show_season_id={this.props.showSeasonId}
                                                main_title_id={this.props?.mainTitleId}
                                                episodeBasedOptions={this.state.episodeOptions}
                                                optionalChkReqUpdated={this.state?.optionalChkReqUpdated}
                                                clearancePermissions={this.props.clearancePermissions}
                                                showName={this.props?.show_name}
                                                seasonName={this.props?.season_name}
                                                episodeNumber = {this.props?.episode_number}
                                                mainTitlePermissions={this.props.mainTitlePermissions}
                                                clearanceRecordPermissionsConfig={this.state.clearanceRecordPermissionsConfig}
                                                clearanceBatchesPermissions={this.props?.isMainTitle ? this.props?.batchesPermissions : this.props?.clearanceBatchesPermissions}
                                            // handleOpenLicensorInlineModals={this.handleOpenLicensorInlineModals}
                                            />
                                        </MDBRow>
                                    </div>
                                </div>
                            </MDBCardText>
                        </MDBCardBody>
                    </MDBCard>
                    {
                        (this.state.showLettersModal || this.state.showMainTitleLettersModal) &&
                        <MainViewModalComponent
                            open={this.state.showLettersModal || this.state.showMainTitleLettersModal}
                            handleClose={this.state.showLettersModal
                                ? this.handleHideLettersModal
                                : this.handleHideMainTitleLettersModal}
                            headerText={"Letters"}
                            mode={""}
                            modalName={"Letters"}
                            licensorData={this.state.licensorData}
                            lic={this.state.lic}
                            licensorListData={this.props.licensorListData}
                            licensorsInfo={this.props?.licensorsInfo}
                            mainTitle={this.state.showMainTitleLettersModal ? "mainTitle" : "clearence"}
                            showSeasonId={this.props.showSeasonId}
                            showId={this.props.showId}
                            seasonId={this.props.seasonId}
                            showSeasonEpisodeId={this.props.showSeasonEpisodeId}
                            clearneceLicenserInfo={this.props.clearneceLicenserInfo}
                            song={this.props?.selectedSong}  //{this.props?.licensorsInfo[0]?.songs_list[0].song_id}
                            lettersPermission={this.state.lettersPermission}
                            lQCPermissions={this.state.lQCPermissions}
                            division_id={this.props?.division_id}
                            EditEmailLetter = {this.props?.EmailLetterPermission!=false ? this.props?.EmailLetterPermission?.edit === 1 ? true : false :false}
                            getClearanceLicensorsDate ={this.getClearanceLicensorsDate}
                        />
                    }
                    {
                        this.state.openPayeeModal &&
                        <MainViewModalComponent
                            open={this.state.openPayeeModal ? true : false}
                            handleClose={() => this.setState({ openPayeeModal: null })}
                            headerText={"Payee"}
                            mode={"Edit"}
                            modalName={"Payee"}
                            id={this.state.openPayeeModal}
                            commonPermissions={this.props?.userContext?.active_tenant?.common_permissions?.filter(item => item.permission_name === "Clearance Payees")}
                            division_id={this.props?.division_id}
                        />
                    }
                    {
                        (this.state.showLicenseTackingModal || this.state.showMainLicenseTackingModal) &&
                        <MainViewModalComponent
                            open={this.state.showLicenseTackingModal || this.state.showMainLicenseTackingModal}
                            handleClose={this.state.showLicenseTackingModal
                                ? this.handleHideLicenseTackingModal
                                : this.handleHideMainLicenseTackingModal}
                            headerText={"License Tracking"}
                            mode={""}
                            type={this.state.lic?.licensor_type}
                            isMainTitle={this.props.isMainTitle}
                            clearanceLicensorId={!this.props.isMainTitle ? this.state.lic.clearance_licensor_id : this.state.lic.main_title_licensor_id}
                            clearenceId={!this.props.isMainTitle ? this.props.clearenceId : this.props.licensorsInfo[0].main_title_id}
                            showSeasonId={!this.props.isMainTitle ? this.props.showSeasonId : this.props.licensorsInfo[0].show_season_id}
                            showId={!this.props.isMainTitle ? this.props.showId : this.props.licensorsInfo[0].show_id}
                            seasonId={!this.props.isMainTitle ? this.props.seasonId : this.props.licensorsInfo[0].season_id}
                            clearneceLicenserInfo={this.props.clearneceLicenserInfo}
                            licensorsInfo={this.props.licensorsInfo}
                            modalName={"LicenseTracking"}
                            licenseTrackingData={this.getLicenseTrackingData}
                            // toggleRefreshPage={this.getLicensorTrakingRecord}
                            toggleRefreshPage={() => {
                                if (!this.props?.isMainTitle) {
                                    this.props?.toggleRefreshPage('EpisodeClearance')
                                } else {
                                    this.props?.toggleRefreshMainTitleLicensor()
                                }
                            }}
                            division_id={this.props?.division_id}
                            licensorTrackingPermission={this.state.licensorTrackingPermission}
                        />
                    }
                    {this.state.showEditLicensorModal && (
                        <MainViewModalComponent
                            open={this.state.showEditLicensorModal}
                            handleClose={this.handleHideEditLicensorModal}
                            headerText={"Licensor"}
                            mode={this.state.isEdit === "Edit" ? "Edit" : "New"}
                            modalName={"Edit Licensor"}
                            id={this.state.lic?.licensor_id}
                            toggleRefreshPage={this.props?.toggleRefreshPage}
                            commonPermissions={commonPermissions}
                        />
                    )}
                    {
                        this.state.showContactModal && (
                            <MainViewModalComponent
                                open={this.state.showContactModal}
                                handleClose={this.handleHideContactModal}
                                headerText={"Change Default Contact"}
                                mode={""}
                                modalName={"Change Contact"}
                                licensorData={this.state.licensorData}
                                licensor_name={this.state.lic?.licensor_name}
                                show_name={this.props?.licensorsInfo[0].show_name}
                                licensorId={this.state.lic?.licensor_id}
                                showId={this.props?.licensorsInfo[0].show_id}
                                showSeasonId={this.props.showSeasonId}
                                division_id={this.props?.division_id}
                                toggleRefreshPage={this.props?.toggleRefreshPage}
                            />
                        )
                    }
                    {
                        this.state.showPayeeModal &&
                        <MainViewModalComponent
                            open={this.state.showPayeeModal}
                            handleClose={this.handleHidePayeeMOdal}
                            headerText={"Payee"}
                            mode={this.state.isEdit === "Edit" ? "Edit" : "New"}
                            modalName={"Payee"}
                            commonPermissions={this.props?.userContext?.active_tenant?.common_permissions?.filter(item => item.permission_name === "Clearance Payees")}
                        />
                    }
                </div>
                {/* )} */}
            </MDBContainer>
        )
    }
});