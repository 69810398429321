
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { MDBModal, MDBModalBody, MDBModalDialog, MDBModalContent } from 'mdb-react-ui-kit';
import CancelIcon from '@material-ui/icons/Cancel';
import { useStyles } from './Config';
import MessageModal from '../../../components/SharedComponents/MessageModal';
import YearsBudgetModal from '../../../components/ShowSeason/Budget/YearsBudgetModal';
import UploadFileModal from '../../../components/ShowSeason/Files/UploadFileModal'
import EditDealModal from '../../../components/ShowSeason/BusinessAffairs/EditDealModal';
import EditPayee from '../../../components/EpisodeAndClearence/EditPayee/EditPayee';
import EFCModal from '../../../components/ShowSeason/Modals/EFCModal';
import AllowRestrictUse from '../../../components/EpisodeAndClearence/EpisodeAndClearenceDetails/AllowRestrictUse';
//import Contact from '../../.../components/EpisodeAndClearence/Contact/Contact'
import OnflyLookup from '../../../components/Navigation/LookupMaintenance/OnflyLookup';
import Contact from '../../../components/EpisodeAndClearence/Contact/Contact'
import AcquireOption from '../../../components/EpisodeAndClearence/AcquireOption/AcquireOption'
import AcquireOptionEmail from '../../../components/EpisodeAndClearence/AcquireOption/AcquireOptionEmail'
import AcquireOptionMainTitle from '../../../components/EpisodeAndClearence/AcquireOption/AcquireOptionMainTitle'
import EditLicensor from '../../../components/EpisodeAndClearence/Licensor/EditLicensor';
import EpisodeViewClearanceRecord from "../../../components/EpisodeViewModals/EpisodeViewClearanceRecord/ClearanceRecord";
import Publisher from "../../../components/EpisodeViewModals/CueSheets/Publisher/Publisher";
import Composer from "../../../components/EpisodeViewModals/CueSheets/Composer/Composer";
import LicensorContacts from '../../../components/EpisodeAndClearence/Licensor/LicensorContacts'
import LicensorPayee from '../../../components/EpisodeAndClearence/Licensor/LicensorPayee'
import CLRecord from '../../../components/EpisodeAndClearence/Licensor/CLRecords'
import LicensorDetails from '../../../components/EpisodeAndClearence/Licensor/LicensorDetails'
import BudgetEFCReport from '../../../components/Navigation/Reports/BudgetEFCReport'
import NewSeason from '../../../components/ShowSeason/Modals/NewSeason'
import CostSplitsDefault from '../../../components/ShowSeason/Modals/CostSplitsDefault'
import CostSplits from '../../../components/ShowSeason/Modals/CostSplits'
import EditShowDetails from '../../../components/ShowSeason/Modals/EditShowDetails'
import Letters from '../../../components/EpisodeAndClearence/Letters/Letters';
import Payincrease from '../../../components/Navigation/TvMusicBudget/Payincrease';
import LicenseTracking from '../../../components/EpisodeAndClearence/LicenseTraking/LicenseTraking';
import EditSong from '../../../components/EpisodeAndClearence/EditSong/EditSong';
import ApplyPayIncrease from '../../../components/ShowSeason/BusinessAffairTabModals/ApplyPayIncrease'
import Batches from '../../../components/BusinessAffairsTabmodals/Batches/Batches';
import EditBudgetTabModal from '../../../components/ShowSeason/EditBudgetTabModal/EditBudgetTabModal';
import EditUnionTabModal from '../../../components/ShowSeason/EditUnionTabModal/EditUnionTabModal';
import './Modal.scss';
import MusicEditor from '../../../components/ShowSeason/BusinessAffairTabModals/MusicEditor'
import ShowComposer from '../../../components/ShowSeason/BusinessAffairTabModals/ShowComposer/ShowComposer'
import PersonModal from '../../../components/ShowSeason/PeopleTabModals/PersonModal/PersonModal';
import ProducerContacts from '../../../components/ShowSeason/PeopleTabModals/ProducerContacts/ProducerContactsModal';
import CueSheetDistribution from '../../../components/ShowSeason/CueSheetTabModals/CueSheetDistribution'
import ImportSeason from '../../../components/Navigation/ImportSeason'
import ImportCueSheet from '../../../components/EpisodeAndClearence/EpisodeAndClearenceDetails/ImportCueSheet';
import NewMainTitleClearanceRecord from '../../../components/ShowSeason/MainTitleTabModals/NewMainTitleClearanceRecord/NewMainTitleClearanceRecord';
import RecurringCue from '../../../components/EpisodeViewModals/RecurringCue/RecurringCue';
import NewEpisode from '../../../components/ShowSeason/Modals/NewEpisode';
import CueSheetAcknowledgementWarnings from '../../../components/ShowSeason/CueSheetAcknowledgementWarnings/CueSheetAcknowledgementWarnings';
import RapidCueAcknowledgements from '../../../components/ShowSeason/RapidCueAcknowledgements/RapidCueAcknowledgements';
import ShowPilotMaintenance from '../../../components/ShowSeason/Modals/ShowPilotMaintenance';
import NotificationComponent from '../../NotificationComponent/NotificationComponent';
import LicensorEntityInfo from '../../../components/EpisodeAndClearence/Licensor/LicenseEntityInfo';
import EditCueSheetPublisherInfo from '../../../components/EpisodeAndClearence/EpisodeAndClearenceDetails/SyncLicensor/EditCueSheetPublisherInfo';
import NewShowSeasonModal from '../../../components/ShowSeason/Modals/NewShowSeasonModal';
import AddShowComposer from '../../../components/ShowSeason/BusinessAffairTabModals/ShowComposer/AddShowComposer';
import CostAllocation from '../../../components/Navigation/CostAllocationCaluculator/CostAllocation';
import MainTitleBatches from '../../../components/ShowSeason/MainTitleTab/MainTitleBatches/MainTitleBatches';
import ShowSeasonComposer from '../../../components/EpisodeViewModals/CueSheets/ShowSeasonComposer/ShowSeasonComposer';
import ScheduledBudgetVsEFC from '../../../components/Navigation/Reports/ScheduledBudgetVsEFC';
import BudgetingSetupModal from '../../../components/ShowSeason/BusinessAffairs/BudgetingSetupModal';
import BudgetingReportModal from '../../../components/ShowSeason/BusinessAffairs/BudgetingReportModal';
import ExcelAndPdfModal from '../../../components/EpisodeViewModals/ExcelAndDocModal/ExcelAndDocModal';
import LettersModal from '../../../components/EpisodeAndClearence/Letters/LettersModal';
class MainViewModalComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            fieldChanged: false,
            showError: false,
            showSuccess: false,
            showFail: false,
            isPosting: false,
            saveTitle: "Success",
            saveEditMessage: "The changes are saved successfully!",
            failTitle: "Failure",
            failMessage: "Saving changes failed!",
            unsavedTitle: "Unsaved Changes",
            unsavedMessage: "Are you sure you want to discard your changes?",
            saveFlag: false,
            show_season_id: this.props?.show_season_id,
            showEmailSave: false,
            showNoDataFound: false
        }
        this.submitButtonRef = React.createRef();
    }
    setPostFlag = (value) => {
        this.setState({ isPosting: value });
    }

    onClose = () => {
        if (this.state.fieldChanged) {
            this.setState({ showError: true });
        } else {
            this.closPopup()
        }
    }
    closPopup = () => {
        this.setState({ showError: false, showFail: false, showSuccess: false, showEmailSave: false, showNoDataFound: false });
        this.props?.handleClose();
    }

    handleSubmit = () => {
        //this.submitButtonRef.current.click();
        this.props?.handleSubmit();
    }
    fieldChanged = (value, mode = "error") => {
        console.log("from fieldChanged");
        switch (mode) {
            case "change": return this.setState({ fieldChanged: value });
            case "error": return this.setState({ showError: value });
            // case "success": return this.setState({ showSuccess: value });
            // case "fail": return this.setState({ showFail: value });
        }
    }

    notificationComponent = (value, mode = "fail") => {
        switch (mode) {
            case "success": return this.setState({ showSuccess: value, saveFlag: true });
            case "fail": return this.setState({ showFail: value });
            case "emailSaved": return this.setState({ showEmailSave: value, saveFlag: true });
            case "noData": return this.setState({ showNoDataFound: value })
        }
    }

    handleResetNotify = () => {
        this.setState({ showFail: false, showSuccess: false, showEmailSave: false, showNoDataFound: false });
        if (this.state.saveFlag) {
            this.setState({ saveFlag: false }, this.closPopup)
        }
    }

    getModalContent = () => {
        console.log('from model',this.props);
        switch (this.props?.modalName) {
            case 'New Audition': return <NewAudition
                onClose={this.onClose}
                mode={this.props.mode || "New"}
                isFeature={false}
                production_season_id={this.props?.production_season_id}
                production_id={this.props?.production_id}
                episode_id={this.props?.episode_id}
                id={this.props?.id}
                fieldChanged={this.fieldChanged}
                handleSubmit={this.props?.handleSubmit}
            />
            case 'On Fly Lookup': return <OnflyLookup
                handleClose={this.onClose}
                {...this.props}
                fieldChanged={this.fieldChanged}
            />
            case 'Payee': return <EditPayee
                getPayeeRemitTo={this.props?.getPayeeRemitTo}
                handleClose={this.onClose}
                mode={this.props?.mode || "New"}
                handleSubmit={this.props?.handleSubmit}
                fieldChanged={this.fieldChanged}
                notificationComponent={this.notificationComponent}
                setPostFlag={this.setPostFlag}
                id={this.props.id}
                commonPermissions={this.props?.commonPermissions}
                division_id={this.props?.division_id}
                {...this.props}
            />
            case 'Change Contact': return <Contact
                handleClose={this.onClose}
                mode={this.props.mode || "New"}
                handleSubmit={this.props?.handleSubmit}
                notificationComponent={this.notificationComponent}
                licensorData={this.props.licensorData}
                licensor_name={this.props?.licensor_name}
                show_name={this.props?.show_name}
                licensorId={this.props.licensorId}
                showId={this.props.showId}
                toggleRefreshPage={this.props?.toggleRefreshPage}
                showSeasonId={this.props.showSeasonId}
                division_id={this.props?.division_id}
            />
            case 'Acquire Option': return <AcquireOption
                handleClose={this.onClose}
                mode={this.props.mode || "New"}
                handleSubmit={this.props?.handleSubmit}
                clearneceLicenserInfo={this.props.clearneceLicenserInfo}
                licensorsInfo={this.props.licensorsInfo}
                isMainTitle={this.props.isMainTitle}
                selectedOptionalRight={this.props?.selectedOptionalRight}
                song_id={this.props?.song_id}
                notificationComponent={this.notificationComponent}
                toggleRefresh={this.props?.toggleRefresh}
                viewAcquireOptionRights={this.props?.viewAcquireOptionRights}
                isEditing={this.props?.isEditing}
                toggleRefreshPage={this.props?.toggleRefreshPage}
                optionAcquisitionModalPermissions={this.props?.optionAcquisitionModalPermissions}
                seasonName={this.props?.seasonName}
                episodeNumber={this.props?.episodeNumber}
                {...this.props}
            />
            case 'Acquire Option Email': return <AcquireOptionEmail
                handleClose={this.onClose}
                mode={this.props.mode || "New"}
                handleSubmit={this.props?.handleSubmit}
                isMainTitle={this.props.isMainTitle}
                seasonName={this.props?.seasonName}
                {...this.props}
            />
            // selectedOptionalRight={this.props?.selectedOptionalRight}
            // song_id={this.props?.song_id}
            // notificationComponent={this.notificationComponent}
            // toggleRefresh={this.props?.toggleRefresh}
            // viewAcquireOptionRights={this.props?.viewAcquireOptionRights}
            // isEditing={this.props?.isEditing}
            // toggleRefreshPage={this.props?.toggleRefreshPage}
            case 'Acquire Option Main Title': return <AcquireOptionMainTitle
                handleClose={this.onClose}
                mode={this.props.mode || "New"}
                handleSubmit={this.props?.handleSubmit}
                selectedOptionalRight={this.props?.selectedOptionalRight}
                song_id={this.props?.song_id}
                notificationComponent={this.notificationComponent}
                toggleRefresh={this.props?.toggleRefresh}
                viewAcquireOptionRights={this.props?.viewAcquireOptionRights}
                isEditing={this.props?.isEditing}
                toggleRefreshPage={this.props?.toggleRefreshPage}
                toggleRefreshAcquisition={this.props?.toggleRefreshAcquisition}
                isMainTitle={this.props.isMainTitle}
                {...this.props}
            />
            case 'Clearance Record': return <EpisodeViewClearanceRecord
                mode={this.props.mode}
                handleClose={this.onClose}
                // mode={this.props.mode || "New"}
                handleSubmit={this.props?.handleSubmit}
                fieldChanged={this.fieldChanged}
                notificationComponent={this.notificationComponent}
                // show_season_id={this.props?.seasonInfo}
                show_season_id={this.props?.show_season_id}
                isClearanceRecord={this.props?.isClearanceRecord}
                show_name={this.props?.show_name}
                season_name={this.props?.season_name}
                episode_name={this.props?.episode_name}
                from={this.props?.from}
                clearenceId={this.props?.clearenceId}
                clearance_divisionId={this.props?.clearance_divisionId}
                episodeId={this.props?.episodeId}
                seasonId={this.props?.seasonId}
                showId={this.props?.showId}
                isMainTitle={this.props?.isMainTitle}
                licensorsInfo={this.props.licensorsInfo}
                division_id={this.props.division_id}
                toggleRefreshClearanceList={this.props?.toggleRefreshClearanceList}
                updateClearanceDetails={this.props?.updateClearanceDetails}
                showSeasonDetails={this.props?.showSeasonDetails}
                clearance_rep_id={this.props.clearance_rep_id}
                toggleRefreshPage={this.props?.toggleRefreshPage}
                commonPermissions={this.props.commonPermissions}
                applyHVPermission={this.props?.applyHVPermission}
                clearancePermissions={this.props.clearancePermissions}
                viewNewCRPermission={this.props?.viewNewCRPermission}
                episodeSongPermissions={this.props?.episodeSongPermissions}
                clearanceRecordsFilter={this.props?.clearanceRecordsFilter}
            />
            case 'Add Publisher': return <Publisher
                handleClose={this.onClose}
                mode={this.props.mode || "Add"}
                handleSubmit={this.props?.handleSubmit}
                handleNodeValue={this.props?.handleNodeValue}
            />
            case 'Add Composer': return <Composer
                handleClose={this.onClose}
                mode={this.props.mode || "Add"}
                handleSubmit={this.props?.handleSubmit}
                handleNodeValue={this.props?.handleNodeValue}
            />
            case 'Edit Licensor': return <EditLicensor
                handleClose={this.onClose}
                mode={this.props.mode || "New"}
                handleSubmit={this.props?.handleSubmit}
                notificationComponent={this.notificationComponent}
                id={this.props?.id}
                commonPermissions={this.props?.commonPermissions}
                toggleRefreshPage={this.props?.toggleRefreshPage}
                songModalUpdate={this.props?.songModalUpdate}
            />
            case 'Licensor Contacts': return <LicensorContacts
                handleClose={this.onClose}
                mode={this.props.mode || "New"}
                handleSubmit={this.props?.handleSubmit}
            />
            case 'Licensor Payee': return <LicensorPayee
                handleClose={this.onClose}
                mode={this.props.mode || "New"}
                handleSubmit={this.props?.handleSubmit}
                fieldChanged={this.fieldChanged}
                setPostFlag={this.setPostFlag}
            />
            case 'CL Records': return <CLRecord
                handleClose={this.onClose}
                mode={this.props.mode || "New"}
                handleSubmit={this.props?.handleSubmit}
            />
            case 'Licensor Details': return <LicensorDetails
                handleClose={this.onClose}
                mode={this.props.mode || "New"}
            />
            case 'Letters': return <Letters
                handleClose={this.onClose}
                mode={this.props.mode || "New"}
                handleSubmit={this.props?.handleSubmit}
                licensorData={this.props?.licensorData}
                licensorListData={this.props.licensorListData}
                licensorsInfo={this.props?.licensorsInfo}
                lic={this.props?.lic}
                mainTitle={this.props?.mainTitle}
                showSeasonId={this.props.showSeasonId}
                showId={this.props.showId}
                seasonId={this.props.seasonId}
                showSeasonEpisodeId={this.props.showSeasonEpisodeId}
                clearneceLicenserInfo={this.props.clearneceLicenserInfo}
                song={this.props?.song}
                lettersPermission={this.props.lettersPermission}
                lQCPermissions={this.props.lQCPermissions}
                division_id={this.props?.division_id}
                EditEmailLetter={this.props.EditEmailLetter}
                getClearanceLicensorsDate ={this.props?.getClearanceLicensorsDate} 
            />
            case 'LicenseTracking': return <LicenseTracking
                handleClose={this.onClose}
                handleSubmit={this.props?.handleSubmit}
                clearanceLicensorId={this.props.clearanceLicensorId}
                clearenceId={this.props.clearenceId}
                showSeasonId={this.props.showSeasonId}
                show_season_episode_id={this.props?.licensorsInfo[0]?.show_season_episode_id}
                license_id ={this.props?.clearneceLicenserInfo[0]?.licensors_list[0]?.licensor_id}
                showId={this.props.showId}
                seasonId={this.props.seasonId}
                clearneceLicenserInfo={this.props.clearneceLicenserInfo}
                licensorsInfo={this.props.licensorsInfo}
                notificationComponent={this.notificationComponent}
                isMainTitle={this.props.isMainTitle}
                licenseTrackingData={this.props.licenseTrackingData}
                toggleRefreshPage={this.props?.toggleRefreshPage}
                division_id={this.props?.division_id}
                type={this.props?.type}
                licensorTrackingPermission={this.props?.licensorTrackingPermission}
            />
            case 'Song': return <EditSong
                mode={this.props.mode || "New"}
                handleClose={this.onClose}
                handleSubmit={this.props?.handleSubmit}
                notificationComponent={this.notificationComponent}
                songId={this.props?.songId}
                commonPermissions={this.props?.commonPermissions}
                toggleRefreshPage={this.props?.toggleRefreshPage}
                division_id={this.props?.division_id}
                toggleRefreshMainTitleLicensor={this.props?.toggleRefreshMainTitleLicensor}
                isMainTitle={this.props?.isMainTitle}
                songModalPermissions={this.props?.commonPermissions?.find(item => item.permission_group_name == "Songs")}
                handleFilterSearch= {this.props.handleFilterSearch}
            />
            case 'Budget EFCReport': return <BudgetEFCReport
                handleClose={this.onClose}
                mode={this.props.mode || "New"}
                handleSubmit={this.props?.handleSubmit}
                notificationComponent={this.notificationComponent}
                {...this.props}
            />
            case 'Scheduled Budget EFCReport': return <ScheduledBudgetVsEFC
                handleClose={this.onClose}
                mode={this.props.mode || "New"}
                handleSubmit={this.props?.handleSubmit}
                notificationComponent={this.notificationComponent}
                {...this.props}
            />
            case 'Pay Increase': return <Payincrease
                handleClose={this.onClose}
                notificationComponent={this.notificationComponent}
                unionId={this.props?.unionId}
                fieldChanged={this.fieldChanged}
                getTvMusicBudgetDetails={this.props.getTvMusicBudgetDetails}
                handleSubmit={this.props?.handleSubmit}
                onCameraList={this.props.onCameraList}
                onOrchestralList={this.props.onOrchestralList}
                recordingsList={this.props.recordingsList}
            />
            case 'EFCModal': return <EFCModal
                handleClose={this.onClose}
                mode={this.props.mode || "New"}
                handleSubmit={this.props?.handleSubmit}
            />
            case 'AllowRestrictUse': return <AllowRestrictUse
                handleClose={this.onClose}
                handleSubmit={this.props?.handleSubmit}
            />
            case 'NewSeason': return <NewSeason
                notificationComponent={this.notificationComponent}
                handleClose={this.onClose}
                mode={this.props.mode || "New"}
                division_id={this.props.division_id}
                show_season_id={this.props.show_season_id}
                seasonOptions={this.props.seasonOptions}
                show_id={this.props.show_id}
                fetchData={this.props.fetchData}
                {...this.props}
            />
            case 'CostSplits Default': return <CostSplitsDefault
                handleClose={this.onClose}
                mode={this.props.mode || "New"}
                handleSubmit={this.props?.handleSubmit}
            />
            case 'Cue Sheet Acknowledgement Warnings': return <CueSheetAcknowledgementWarnings
                handleClose={this.onClose}
                mode={this.props.mode || "New"}
                handleSubmit={this.props?.handleSubmit}
            />
            case 'Rapid Cue Acknowledgements': return <RapidCueAcknowledgements
                handleClose={this.onClose}
                mode={this.props.mode || "New"}
                handleSubmit={this.props?.handleSubmit}
                {...this?.props}
            />
            case 'Pilot Maintenance': return <ShowPilotMaintenance
                handleClose={this.onClose}
                mode={this.props.mode || "New"}
                handleSubmit={this.props?.handleSubmit}
                initialRecordData={this.props?.initialRecordData}
                show_season_id={this.props?.show_season_id}
                notificationComponent={this.notificationComponent}
                fetchPilotMainDetails={this.props.fetchPilotMainDetails}
                fetchPilotHeaderData={this.props.fetchPilotHeaderData}
            />
            case 'Music Editor': return <MusicEditor
                handleClose={this.onClose}
                mode={this.props.mode || "New"}
                fieldChanged={this.fieldChanged}
                setPostFlag={this.setPostFlag}
                notificationComponent={this.notificationComponent}
                {...this.props}
            />
            case 'Show Composer': return <ShowComposer
                handleClose={this.onClose}
                mode={this.props.mode || "New"}
                fieldChanged={this.fieldChanged}
                setPostFlag={this.setPostFlag}
                notificationComponent={this.notificationComponent}
                {...this.props}
            />
            case 'Music Supervisor': return <ShowComposer
                handleClose={this.onClose}
                mode={this.props.mode || "New"}
                fieldChanged={this.fieldChanged}
                setPostFlag={this.setPostFlag}
                notificationComponent={this.notificationComponent}
                {...this.props}
            />
            case 'Edit Cost Splits': return <CostSplits
                handleClose={this.onClose}
                mode={this.props.mode || "New"}
                handleSubmit={this.props?.handleSubmit}
                showSeasonDetails={this.props?.showSeasonDetails}
                notificationComponent={this.notificationComponent}
                seasonId={this.props?.seasonId}
                division_id={this.props?.division_id}
                costSpiltFromSeason={this.props?.costSpiltFromSeason}
                fieldChanged={this.fieldChanged}
                toggleRefresh={this.props?.toggleRefresh}
                isMaintitle={this.props?.isMaintitle}
                commonPermissions={this.props?.commonPermissions}
            />
            case 'Edit Cue Sheet Publisher Info': return <EditCueSheetPublisherInfo
                handleClose={this.onClose}
                mode={this.props.mode || "New"}
                handleSubmit={this.props?.handleSubmit}
                fieldChanged={this.fieldChanged}
                clearneceLicenserInfo={this.props.clearneceLicenserInfo}
                licensorsInfo={this.props?.licensorsInfo}
                lic={this.props.lic}
                selectedSong={this.props?.selectedSong}
                isMainTitle={this.props?.isMainTitle}
                notificationComponent={this.notificationComponent}
                toggleRefreshCueSheetPublisherDetails={this.props?.toggleRefreshCueSheetPublisherDetails}
                divisionId={this.props?.divisionId}
            />
            case 'Person': return <PersonModal
                handleClose={this.onClose}
                mode={this.props.mode || "New"}
                fieldChanged={this.fieldChanged}
                setPostFlag={this.setPostFlag}
                notificationComponent={this.notificationComponent}
                {...this.props}
                peopleModalPopupPermissions={this.props.peopleModalPopupPermissions}
            />
            case 'Edit ShowDetails': return <EditShowDetails
                notificationComponent={this.props.origin === "dashboard" ? this.props.notificationComponent : this.notificationComponent}
                handleClose={this.onClose}
                {...this.props} />
            case 'Edit clearance subtab details': return <YearsBudgetModal notificationComponent={this.notificationComponent} handleClose={this.onClose} {...this.props} />
            case 'Cue Sheet Distribution': return <CueSheetDistribution
                handleClose={this.onClose}
                mode={this.props.mode || "New"}
                handleSubmit={this.props?.handleSubmit}
                showSeasonData={this.props?.showSeasonData}
                fieldChanged={this.fieldChanged}
                setPostFlag={this.setPostFlag}
                notificationComponent={this.notificationComponent}
                showSeasonEpisodeId={this.props.showSeasonEpisodeId}
                isCueSheetDistribution={this.props?.isCueSheetDistribution}
                commonPermissions={this.props?.permissionArr}
            />
            case 'Edit Producer Contacts': return <ProducerContacts
                handleClose={this.onClose}
                mode={this.props.mode || "New"}
                handleSubmit={this.props?.handleSubmit}
                show_season_id={this.props?.show_season_id}
                notificationComponent={this.notificationComponent}
                show_id={this.props?.show_id}
                season_id={this.props?.season_id}
                division_id={this.props.division_id}
                fetchData={this.props.fetchData}
            />
            case 'Edit Deal': return <EditDealModal
                handleClose={this.onClose}
                mode={this.props.mode || "New"}
                notificationComponent={this.notificationComponent}
                {...this.props}
            />
            case 'Import Season': return <ImportSeason
                handleClose={this.onClose}
                mode={this.props.mode || "New"}
                handleSubmit={this.props?.handleSubmit}
                notificationComponent={this.notificationComponent}
                fieldChanged={this.fieldChanged}
            />
            case 'Import Cue Sheet Data': return <ImportCueSheet
                handleClose={this.onClose}
                mode={this.props.mode || "New"}
                handleSubmit={this.props?.handleSubmit}
                notificationComponent={this.notificationComponent}
                fieldChanged={this.fieldChanged}
                {...this.props}
            />
            case 'Apply Pay Increase': return <ApplyPayIncrease
                handleClose={this.onClose}
                handleSubmit={this.props?.handleSubmit}
                scAndMSdetails={this.props.scAndMSdetails}
                notificationComponent={this.notificationComponent}
                fieldChanged={this.fieldChanged}
                {...this.props}
            />
            case 'Batches': return <Batches
                handleClose={this.onClose}
                handleSubmit={this.props?.handleSubmit}
                notificationComponent={this.notificationComponent}
                fieldChanged={this.fieldChanged}
                show_season_episode_id={this.props?.show_season_episode_id}
                isMainTitle={this.props?.isMainTitle}
                show_season_id={this.props?.show_season_id}
                main_title_id={this.props?.main_title_id}
                isBusinessAffairs={this.props?.isBusinessAffairs}
                clearanceBatchesPermissions={this.props?.isMainTitle ? this.props?.batchesPermissions : this.props?.clearanceBatchesPermissions}
                division_id={this.props?.division_id}
            />
            case "MainTitle Batches": return <MainTitleBatches
                handleClose={this.onClose}
                handleSubmit={this.props?.handleSubmit}
                notificationComponent={this.notificationComponent}
                fieldChanged={this.fieldChanged}
                show_season_episode_id={this.props?.show_season_episode_id}
                isMainTitle={this.props?.isMainTitle}
                show_season_id={this.props?.show_season_id}
                right_id={this.props?.right_id}
                main_title_id={this.props?.main_title_id}
                licOptionalId={this.props?.licOptionalId}
                clearanceBatchesPermissions={this.props?.clearanceBatchesPermissions}
                divisionId={this.props?.divisionId}
                isHVOptionalRight={this.props?.isHVOptionalRight}
            />
            case 'UploadFile': return <UploadFileModal
                handleClose={this.onClose}
                handleSubmit={this.props?.handleSubmit}
                notificationComponent={this.notificationComponent}
                fieldChanged={this.fieldChanged}
                {...this.props}
            />
            case 'Edit Budget Tab Modal': return <EditBudgetTabModal
                handleClose={this.onClose}
                mode={this.props.mode || "New"}
                notificationComponent={this.notificationComponent}
                fieldChanged={this.fieldChanged}
                {...this.props}
            />
            case 'Edit Union': return <EditUnionTabModal
                handleClose={this.onClose}
                mode={this.props.mode || "New"}
                notificationComponent={this.notificationComponent}
                fieldChanged={this.fieldChanged}
                {...this.props}
            />
            case 'Main Title Clearance Record': return <NewMainTitleClearanceRecord
                mode={this.props.mode || "New"}
                handleClose={this.onClose}
                handleSubmit={this.props?.handleSubmit}
                fieldChanged={this.fieldChanged}
                notificationComponent={this.notificationComponent}
                show_season_id={this.props?.show_season_id}
                show_name={this.props?.show_name}
                season_name={this.props?.season_name}
                from={this.props?.from}
                seasonId={this.props?.seasonId}
                showId={this.props?.showId}
                isMainTitle={this.props?.isMainTitle}
                mainTitleId={this.props?.mainTitleId}
                licensorsInfo={this.props.licensorsInfo}
                division_id={this.props?.division_id}
                toggleRefreshClearanceList={this.props?.toggleRefreshClearanceList}
                toggleRefreshMainTitle={this.props?.toggleRefreshMainTitle}
                updateMainTitleDetails={this.props?.updateMainTitleDetails}
                updateClearanceDetails={this.props?.updateClearanceDetails}
                showSeasonDetails={this.props?.showSeasonDetails}
                clearance_rep_id={this.props.clearance_rep_id}
                clearance_rep={this.props.clearance_rep}
                episodeLength={this.props.episodeLength}
                toggleRefreshPage={this.props?.toggleRefreshPage}
                toggleRefreshMainTitleLicensor={this.props?.toggleRefreshMainTitleLicensor}
                mainTitlePermissions={this.props?.mainTitlePermissions}
                episodeSongPermissions={this.props?.episodeSongPermissions}
                {...this.props}
            />
            case 'RecurringCue': return <RecurringCue
                handleClose={this.onClose}
                mode={this.props.mode || "New"}
                handleSubmit={this.props?.handleSubmit}
                showSeasonId={this.props?.showSeasonId}
                showId={this.props?.showId}
                seasonId={this.props?.seasonId}
                notificationComponent={this.notificationComponent}
                fieldChanged={this.fieldChanged}
                toggleRefresh={this.props?.toggleRefresh}
                permissionCreateSubmitCueSheets={this.props?.permissionCreateSubmitCueSheets}
                division_id={this.props?.division_id}
                showSeasonEpisodeId={this.props?.showSeasonEpisodeId}
                {...this.props}
            />
            case 'Episode': return <NewEpisode
                mode={this.props?.mode || "New"}
                handleClose={this.onClose}
                handleSubmit={this.props?.handleSubmit}
                // setPostFlag={this.setPostFlag}
                // fieldChanged={this.fieldChanged}
                notificationComponent={this.notificationComponent}
                showSeasonId={this.props?.show_season_id}
                initialRecordData={this.props?.initialRecordData}
                selectedEpisodeId={this.props?.selectedEpisodeId}
                show_season_id={this.props?.show_season_id}
                main_title_id={this.props?.main_title_id}
                fetchData={this.props?.fetchData}
                permissionArr={this.props?.permissionArr}
                newEpisodeNumber={this.props?.newEpisodeNumber}
                {...this.props} />
            case 'License Entity Info': return <LicensorEntityInfo
                handleClose={this.onClose}
                handleSubmit={this.props?.handleSubmit}
                closeEntityModal={this.props?.closeEntityModal}
                notificationComponent={this.notificationComponent}
                fieldChanged={this.fieldChanged}
                license_entity_id={this.props?.license_entity_id}
                licensor_id={this.props?.licensor_id}
                handleGeneralEdit={this.props?.handleGeneralEdit}
            />
            case 'Show Season': return <NewShowSeasonModal
                mode={this.props?.mode || "New"}
                handleClose={this.onClose}
                handleSubmit={this.props?.handleSubmit}
                notificationComponent={this.notificationComponent}
                setPostFlag={this.setPostFlag}
                fieldChanged={this.fieldChanged}
            />
            case 'Add ShowComposer': return <AddShowComposer
                mode={"New"}
                handleClose={this.onClose}
                notificationComponent={this.notificationComponent}
                setPostFlag={this.setPostFlag}
                fieldChanged={this.fieldChanged}
                {...this.props}
            />
            case 'Cost Allocation Calculator': return <CostAllocation
                mode={this.props?.mode || "New"}
                handleClose={this.onClose}
                handleSubmit={this.props?.handleSubmit}
                fieldChanged={this.fieldChanged}
                notificationComponent={this.notificationComponent}
            />
            case 'ShowSeasonComposer': return <ShowSeasonComposer
                handleClose={this.onClose}
                mode={this.props.mode || "New"}
                handleSubmit={this.props?.handleSubmit}
                showSeasonId={this.props?.showSeasonId}
                showId={this.props?.showId}
                seasonId={this.props?.seasonId}
                notificationComponent={this.notificationComponent}
                fieldChanged={this.fieldChanged}
                toggleRefresh={this.props?.toggleRefresh}
                permissionCreateSubmitCueSheets={this.props?.permissionCreateSubmitCueSheets}
                division_id={this.props?.division_id}
            />
            case 'SC/MS Budgeting Setup': return <BudgetingSetupModal
                handleClose={this.onClose}
                mode={this.props.mode || "New"}
                handleSubmit={this.props?.handleSubmit}
                notificationComponent={this.notificationComponent}
                fieldChanged={this.fieldChanged}
                showSeasonId={this.props?.showSeasonId}
                divisionId={this.props?.divisionId}
                seasonId={this.props?.seasonId}
                showId={this.props?.showId}
            />
            case 'Budgeting Report Options': return <BudgetingReportModal
                handleClose={this.onClose}
                mode={this.props.mode || "New"}
                handleSubmit={this.props?.handleSubmit}
                notificationComponent={this.notificationComponent}
                fieldChanged={this.fieldChanged}
                showSeasonId={this.props?.showSeasonId}
                divisionId={this.props?.divisionId}
            />
            case 'Excel And Doc Modal' : return <ExcelAndPdfModal 
                handleClose={this.onClose}
                mode={this.props.mode || "New"}
                handleSubmit={this.props?.handleSubmit}
                notificationComponent={this.notificationComponent}
                downloadExcel={this.props.downloadExcel}
                isOpenExisting={this.props?.isOpenExisting}
                isExcelPosting={this.props.isExcelPosting}
            />
            case 'Letters Modal' : return <LettersModal 
                handleClose={this.onClose}
                mode={this.props.mode || "New"}
                handleSubmit={this.props?.handleSubmit}
                notificationComponent={this.notificationComponent}
                excelRecord={this.props?.excelRecord}
                userContext ={this.props?.userContext}
                fileName={this.props.fileName}
                division_id={this.props.division_id}
                getDataToOpenExisting={this.props?.getDataToOpenExisting}
                lettersTabList={this.props?.lettersTabList}
                isMainTitle={this.props?.isMainTitle}
                {...this.props}
            />
        }

    }

    getWidthForModal = () => {
        switch (this.props?.modalName) {
            case 'Edit ShowDetails': return 'showSeasonModalWidth'
            case 'Acquire Option': return 'acquireOptionModalWidth'
            case 'LicenseTracking' : return 'showSeasonModalWidth'
            case 'Acquire Option Email': return this.props?.modalClass
            case 'Budget EFCReport': return 'budgetVSEFCModalWidth'
            default: return '';

        }
    }

    getClasses = () => {
        const { classes } = this.props;
        switch (this.props?.modalName) {
            case 'New Set': return classes.AddSetContainer;
            case 'Add Publisher': return classes.AddPublisherContainer;
            case 'Add Composer': return classes.AddComposerContainer;
            case 'Person': return classes.PersonContainer;
            case 'Edit Cue Sheet Publisher Info': return classes.EditCueSheetPublisherInfoContainer;
            case 'Pay Increase': return classes.PayeeIncreaseContainer;
             case 'Cost Allocation Calculator': return classes.CostAllocationCalculatorContainer;
             case 'Cue Sheet Acknowledgement Warnings': return classes.CueSheetAcknowledgementWarningsContainer;
             case 'Edit Cost Splits': return classes.EditCostSplitsContainer;
             case 'Episode': return classes.EpisodeContainer;
             case 'Song': return classes.SongContainer;
             case 'Payee': return classes.PayeeContainer;
             case 'RecurringCue': return classes.RecurringCueContainer;
            case 'Cue Sheet Distribution': return classes.CueSheetDistributionContainer;
            case 'MainTitle Batches': return classes.mainTitleBatchesContainer;
            case 'Batches': return classes.mainTitleBatchesContainer;
            // case 'Main Title Clearance Record': return classes.NewMainTitleContainer;
            default: return '';
        }
    }
    getClassesForModal = () => {
        const { classes } = this.props;
        switch (this.props?.modalName) {
            case 'New Set': return 'sm';
            case 'EFCModal': return 'sm';
            case 'Pay Increase': return 'xs';
            case 'AllowRestrictUse': return 'xs';
            case 'Add ShowComposer': return 'xs';
            case 'Payee': return 'lg';
            case 'Change Contact': return 'sm';
            // case 'Clearance Record': return 'md';
            case 'Acquire Option': return 'lg';
            case 'Edit Licensor': return 'lg';
            case 'Add Publisher': return 'lg';
            case 'Add Composer': return 'lg';
            case 'SC/MS Budgeting Setup': return 'lg';
            case 'Licensor Contacts': return 'lg';
            case 'Licensor Payee': return 'lg';
            case 'CL Records': return 'lg';
            case 'Licensor Details': return 'lg';
            case 'Budget EFCReport': return 'lg';
            case 'NewSeason': return 'md';
            case 'CostSplits Default': return 'lg'
            case 'Edit Cost Splits': return 'lg'
            case 'Clearance Record': return 'lg'
            case 'Edit Show Composer': return 'lg';
            case 'Music Supervisor': return 'lg';
            case 'Edit ShowDetails': return 'lg'
            case 'Letters': return 'lg';
            case 'LicenseTracking': return 'lg';
            case 'Song': return 'lg';
            case 'Music Editor': return 'lg';
            case 'Show Composer': return 'lg';
            case "Person": return 'lg';
            case 'Batches': return 'lg';
            case 'MainTitle Batches': return 'lg';
            case 'Apply Pay Increase': return 'md';
            case 'Edit Budget Tab Modal': return 'md';
            case 'Cue Sheet Distribution': return 'md';
            case 'Import Season': return 'sm';
            case 'Main Title Clearance Record': return 'lg';
            case 'RecurringCue': return 'lg';
            case 'Episode': return 'lg';
            case 'Cue Sheet Acknowledgement Warnings': return 'lg';
            case 'Rapid Cue Acknowledgements': return 'lg';
            case 'Pilot Maintenance': return 'md';
            case 'License Entity Info': return 'md';
            case 'Show Season': return 'md';
            case 'Add ShowComposer': return 'sm';
            case 'Cost Allocation Calculator': return 'lg';
            case 'Edit Cue Sheet Publisher Info': return 'md';
            case 'ShowSeasonComposer': return 'lg';
            case 'Excel And Doc Modal': return 'sm';
            default: return 'md';
        }
    }

    render() {
        const { classes } = this.props;
        let message = this.state.showError ? this.state.unsavedMessage : this.state.showFail ? this.state.failMessage :
            (this.state.showSuccess && (this.props.mode === "Edit")) ? this.state.saveEditMessage :
                (this.state.showSuccess && (this.props.mode === "New")) ? `New ${this.props?.headerText} has been created!` :
                    (this.state.showSuccess && (this.props.mode === "Both")) ? this.state.saveEditMessage : "";
        let comfirmModalTitle = this.state.showError ? this.state.unsavedTitle : this.state.showFail ? this.state.failMessage :
            this.state.showSuccess ? this.state.saveTitle : "";
        let severity = this.state.showFail ? "error" : this.state.showSuccess ? "success" : this.state.showEmailSave ? "success" : this.state.showNoDataFound ? "error" : "";
        let messageNotification = this.state.showSuccess ? "The changes are saved successfully!" : this.state.showFail ? "Saving changes failed!" : this.state.showEmailSave ? "Emails have been saved to your Drafts folder!" : this.state.showNoDataFound ? "There are no shows for this season" : "";
        return (
            <div className={"MainViewModalContainer " + this.getWidthForModal()}>
                <NotificationComponent open={this.state.showSuccess || this.state.showFail || this.state.showEmailSave || this.state.showNoDataFound} message={messageNotification} severity={severity}
                    handleResetNotify={this.handleResetNotify.bind(this)} />
                {/* <Modal
                    open={this.props?.open ? this.props?.open : false}
                    onClose={this.onClose}
                    className={classes.modal}

                > */}
                <MDBModal staticBackdrop closeOnEsc={false} tabIndex='-1'
                    show={this.props?.open ? this.props?.open : false}>
                    <MDBModalDialog size={this.getClassesForModal()} className={this.getClasses()}>
                        <MDBModalBody>
                            <MDBModalContent>
                                <div>
                                    <MDBContainer fluid className="padding-hor-12">
                                        <MessageModal
                                            open={this.state.showError || false}
                                            title={comfirmModalTitle}
                                            message={message}
                                            hideCancel={false}
                                            primaryButtonText={"OK"}
                                            secondaryButtonText={"Cancel"}
                                            onConfirm={() => this.closPopup()}
                                            handleClose={(e) => this.setState({ showFail: false, showError: false })} />
                                        {/* <MessageModal
                                            open={this.state.showError || this.state.showFail || this.state.showSuccess || false}
                                            title={comfirmModalTitle}
                                            message={message}
                                            hideCancel={this.state.showFail || this.state.showSuccess || false}
                                            primaryButtonText={"OK"}
                                            secondaryButtonText={"Cancel"}
                                            onConfirm={() => this.closPopup()}
                                            handleClose={(e) => this.setState({ showError: false, showFail: false, showSuccess: false })} /> */}
                                        <MDBRow className="ModalContent">
                                            <MDBCol>
                                                {this.props?.headerText &&
                                                    <MDBRow className="ModalHeader" id={"modal_header"}>
                                                        <MDBCol md={11} className="HeaderText" >{(this.props.mode === 'Both' ? "" : this.props.mode) + " " + this.props?.headerText}</MDBCol>
                                                        {/* <MDBCol md={4} className="HeaderText" ></MDBCol> */}
                                                        <MDBCol md={1} className="closeIcon" onClick={this.onClose} ><CancelIcon style={{ fill: "white" }} /></MDBCol>
                                                    </MDBRow>
                                                }
                                                <MDBRow className="ModalBody">
                                                    <MDBCol md={12} className="Content">
                                                        {this.getModalContent()}
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow className="footer">

                                                </MDBRow>
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBContainer>
                                </div>
                                {/* </Modal> */}
                            </MDBModalContent>
                        </MDBModalBody>
                    </MDBModalDialog>
                </MDBModal>
            </div>
        );
    }
}

export default withStyles(useStyles)(MainViewModalComponent);
